import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@digital-retail/falabella-ui-cross-components';
import SoftLineMobileStyles from './SoftLineMobile.style';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import PDPBasicInfoMobile from './containers/PDPBasicInfoMobile/PDPBasicInfoMobile';
import ProductSpecification from '../../ProdctSpecifications/ProductSpecifications';
import { withProduct } from '../../../utils/ProductContext';
import { withLabels } from '../../../utils/LabelsContext';
import ProductOutOfStock from '../../../components/ProductOutOfStock/ProductOutOfStock';
import constants from '../../../config/constants';
import Helpline from '../../Helpline/HelpLine';
import { withUser } from '../../../utils/UserContext';
import ConnectTab from '../../HardLineProductContainer/ConnectTab/ConnectTab';
import ReturnPolicy from '../../ReturnPolicy/ReturnPolicy';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import ComboItemsMobile from '../../../components/ComboSection/ComboItemsMobile';
import _ from '../../../utils/LodashImports';
import { RatingAndReviews } from '../../../external_dependencies/ratingAndReviews/RatingAndReviews';
import CMRApertura from '../../../components/CMRApertura/CMRApertura';
import { getVariant } from '../../../utils/variant';

const PDPMobile = ({
  product,
  labels,
  crop,
  headlineImageHeight,
  headlineImageWidth,
  zoomSize,
  isVendorLogin,
  appCtx
}) => {
  const {
    isOutOfStock,
    breadCrumb,
    name: productName,
    variants,
    currentVariant,
    cmrBannerActive = true,
    darSectionActive
  } = product;
  const variant = getVariant(variants, currentVariant);
  const comboData = _.get(variant, 'comboData', null);
  const { prices, cmrSavings } = variant;

  const isSoCom = appCtx.store === constants.STORES.so_com;
  const showCMRApertura = isSoCom
    ? _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabledForSoCom', false)
    : _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabled', false);

  const productSpecification = (
    <ProductSpecification layoutType={constants.PRODUCT_CONSTANTS.SOFTLINE} />
  );
  const productSpecificationView = () => {
    if (!isVendorLogin) return productSpecification;

    const tabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon internet-icon-active" />
            ) : (
              <span className="icon internet-icon-disabled" />
            )}
            <p>INTERNET</p>
            <style jsx>{SoftLineMobileStyles}</style>
          </div>
        ),
        content: () => productSpecification
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon tienda-active" />
            ) : (
              <span className="icon tienda-disabled" />
            )}
            <p>TIENDA</p>
            <style jsx>{SoftLineMobileStyles}</style>
          </div>
        ),
        content: () => (
          <ProductSpecification
            layoutType={constants.PRODUCT_CONSTANTS.SOFTLINE}
            currentTab="Tienda"
          />
        )
      }
    ];
    return <ConnectTab tabData={tabData} />;
  };

  if (isOutOfStock) {
    return (
      <div className="pdp-body">
        <div className="pdp-body-container">
          <Breadcrumbs
            items={breadCrumb}
            productName={productName}
            isPdpPage
            tenant={appCtx.regionCode}
            host={appCtx.host}
            path={appCtx.url}
          />
          <div className="pdp-container out-of-stock">
            <section className="pdp-detail-section oos-wrapper">
              <ProductOutOfStock />
            </section>
            <div className="headline-image-and-details">
              <section className="pdp-image-section">
                {/* modify this component for PDP image view */}
                <ImageGallery />
              </section>
              <section className="pdp-detail-section">
                <PDPBasicInfoMobile product={product} labels={labels} />
                {productSpecificationView()}
              </section>
            </div>
            <div className="rating_summary-wrapper">
              <RatingAndReviews />
            </div>
            {comboData && (
              <div className="combo-wrapper">
                <ComboItemsMobile comboData={comboData} />
              </div>
            )}
          </div>
        </div>
        <style jsx>{SoftLineMobileStyles}</style>
      </div>
    );
  }

  return (
    <div className="pdp-body">
      <div className="pdp-body-container">
        <Breadcrumbs
          items={breadCrumb}
          productName={productName}
          isPdpPage
          tenant={appCtx.regionCode}
          host={appCtx.host}
          path={appCtx.url}
        />
        <div className="pdp-container">
          <section className="pdp-detail-section">
            <PDPBasicInfoMobile product={product} labels={labels} />
          </section>
          <section className="pdp-image-section">
            {/* modify this component for PDP image view */}
            <ImageGallery
              crop={crop}
              headlineImageHeight={headlineImageHeight}
              headlineImageWidth={headlineImageWidth}
              zoomSize={zoomSize}
              deviceType="mobile"
            />
          </section>
          <section className="pdp-detail-section">
            {isOutOfStock ? <ProductOutOfStock /> : productSpecificationView()}
          </section>
        </div>
        {!darSectionActive && (
          <ReturnPolicy returnPolicy={product.returnPolicy} />
        )}
        <Helpline product={product} />
        {showCMRApertura && cmrBannerActive && (
          <CMRApertura prices={prices} cmrSavings={cmrSavings} />
        )}
      </div>
      <style jsx>{SoftLineMobileStyles}</style>
    </div>
  );
};

PDPMobile.defaultProps = {
  crop: { x: 248, y: 0 },
  headlineImageHeight: 1500,
  headlineImageWidth: 1004,
  zoomSize: { x: 1004, y: 1500 }
};
PDPMobile.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  crop: PropTypes.object,
  headlineImageHeight: PropTypes.number,
  headlineImageWidth: PropTypes.number,
  zoomSize: PropTypes.object,
  isVendorLogin: PropTypes.bool.isRequired,
  appCtx: PropTypes.object.isRequired
};

export { PDPMobile };
export default withUser(
  withProduct(withLabels(withApplicationContext(PDPMobile)))
);
