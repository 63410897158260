import css from 'styled-jsx/css';
import { icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

export const puntospesos = css`
  i.puntospesos-icon {
    width:24px;
    height:12px;  
    background-image: url("${svgToDataUri(icons.cmrPuntos)}");
    display:inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: -1px 2px;
  }
`;

const styles = css`
  .mtp-pod-badges {
    @mixin mobileToTablet {
      height: 24px;
    }

    &-LIST {
      padding: 7px 0 6px 0;
      height: 32px;
      @mixin mobileToTablet {
        height: 38px;
        padding: 16px 0 3px 0;
      }
    }
    .pod-badges-item {
      padding: 2px 4px;
      border-radius: 3px;
      line-height: 14px;
      margin-left: 4px;

      &.inPdp {
        @mixin mobileToTablet {
          line-height: 17px;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
  .mtp-pod-badges span {
    font-size: 14px;
    @mixin mobileToTablet {
      font-size: 12px;
    }
  }
`;

export default styles;
