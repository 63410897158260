import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@digital-retail/falabella-ui-cross-components';
import HardLineDesktopStyle from './HardLineDesktop.style';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import { withProduct } from '../../../utils/ProductContext';
import { withLabels } from '../../../utils/LabelsContext';
import PDPBasicInfoDesktop from './containers/PDPBasicInfoDesktop/PDPBasicInfoDesktop';
import ProductSpecifications from '../../ProdctSpecifications/ProductSpecifications';
import ProductOutOfStock from '../../../components/ProductOutOfStock/ProductOutOfStock';
import ConnectTab from '../ConnectTab/ConnectTab';
import ProductSpecificationConnect from '../../../components/ProductSpecificationConnect/ProductSpecificationConnect';
import * as productUtils from '../../../utils/product/productUtils';
import Helpline from '../../Helpline/HelpLine';
import { withUser } from '../../../utils/UserContext';
import ReturnPolicy from '../../ReturnPolicy/ReturnPolicy';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import ComboItemsDesktop from '../../../components/ComboSection/ComboItemsDesktop';
import { getVariant } from '../../../utils/variant';
import _ from '../../../utils/LodashImports';

const PDPDesktop = ({ product, labels, isVendorLogin, appCtx }) => {
  const { isOutOfStock, breadCrumb, name: productName } = product;
  const isConnectProduct = productUtils.isConnectProduct(product);
  const { variants, currentVariant, darSectionActive } = product;
  const variant = getVariant(variants, currentVariant);
  const comboData = _.get(variant, 'comboData', null);
  const tabData = [
    {
      title: () => labels.TEAM,
      content: () => <ProductSpecifications />
    },
    {
      title: () => labels.PLANES,
      content: () => <ProductSpecificationConnect />
    }
  ];
  const renderProductSpecifications = () => {
    return (
      <div>
        {isConnectProduct ? (
          <ConnectTab
            variant={appCtx.isRebrandingEnabled ? 'rebranding' : 'primary'}
            tabData={tabData}
          />
        ) : (
          <ProductSpecifications />
        )}
      </div>
    );
  };
  const renderAgentLoginView = () => {
    const connectTabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon internet-icon-active" />
            ) : (
              <span className="icon internet-icon-disabled" />
            )}
            <p>INTERNET</p>
            <style jsx>{HardLineDesktopStyle}</style>
          </div>
        ),
        content: () => <ConnectTab variant="secondary" tabData={tabData} />
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon tienda-active" />
            ) : (
              <span className="icon tienda-disabled" />
            )}
            <p>TIENDA</p>
            <style jsx>{HardLineDesktopStyle}</style>
          </div>
        ),
        content: () => <ConnectTab variant="secondary" tabData={tabData} />
      }
    ];
    const normalTabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon internet-icon-active" />
            ) : (
              <span className="icon internet-icon-disabled" />
            )}
            <p>INTERNET</p>
            <style jsx>{HardLineDesktopStyle}</style>
          </div>
        ),
        content: () => <ProductSpecifications />
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? (
              <span className="icon tienda-active" />
            ) : (
              <span className="icon tienda-disabled" />
            )}
            <p>TIENDA</p>
            <style jsx>{HardLineDesktopStyle}</style>
          </div>
        ),
        content: () => <ProductSpecifications currentTab="Tienda" />
      }
    ];
    return (
      <div>
        <ConnectTab
          tabData={isConnectProduct ? connectTabData : normalTabData}
        />
      </div>
    );
  };
  const productSpecifications = () => {
    if (isOutOfStock) return <ProductOutOfStock />;
    if (!isVendorLogin) return renderProductSpecifications();
    if (isVendorLogin) return renderAgentLoginView();
    return null;
  };
  return (
    <Fragment>
      <Breadcrumbs
        items={breadCrumb}
        productName={productName}
        isPdpPage
        tenant={appCtx.regionCode}
        host={appCtx.host}
        path={appCtx.url}
      />
      {isOutOfStock ? (
        <div className="pdp-container out-of-stock">
          <section className="pdp-image-section">
            <ImageGallery />
          </section>
          <section className="pdp-detail-section">
            <ProductOutOfStock />
            <PDPBasicInfoDesktop product={product} labels={labels} />
            {!isConnectProduct && renderProductSpecifications()}
            {comboData && <ComboItemsDesktop comboData={comboData} />}
          </section>
        </div>
      ) : (
        <div className="pdp-container">
          <section className="pdp-image-section">
            <ImageGallery />
            <Helpline product={product} />
            {!darSectionActive && (
              <ReturnPolicy returnPolicy={product.returnPolicy} />
            )}
          </section>
          <section className="pdp-detail-section">
            <PDPBasicInfoDesktop product={product} labels={labels} />
            {!isConnectProduct && <hr className="divider-line" />}
            {productSpecifications()}
            {comboData && <ComboItemsDesktop comboData={comboData} />}
          </section>
        </div>
      )}

      <style jsx>{HardLineDesktopStyle}</style>
    </Fragment>
  );
};

PDPDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  isVendorLogin: PropTypes.bool.isRequired,
  appCtx: PropTypes.object
};
PDPDesktop.defaultProps = {
  appCtx: {}
};
export default withUser(
  withProduct(withLabels(withApplicationContext(PDPDesktop)))
);
export { PDPDesktop };
