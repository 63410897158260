import { useState, useCallback } from 'react';
import {
  getFuntionAnalyticsSponsored,
  setSponsoredDisplayDigitalDataImpression,
  setSponsoredDisplayDigitalDataClick
} from '../../containers/ProductContainer/utils';

const useBannerActionHandler = ({
  appCtx,
  config,
  categoryName,
  horizontalProduct,
  verticalProduct
}) => {
  const [hasImpressionBeenSent, setHasImpressionBeenSent] = useState(false);

  const { tenant, deviceType, regionCode, store } = appCtx;
  const {
    MABAYA_TOKEN,
    MABAYA_EVENTS_ENABLED,
    FEATURE_TOGGLE_SPONSORED_VAS_SERVICE
  } = config;

  const handleBannerAction = useCallback(
    (product, action) => {
      if (product == null) return;
      const filteredProducts = [
        horizontalProduct?.id,
        verticalProduct?.id
      ].filter(Boolean);
      const sku = product.id;
      const mPos = sku === horizontalProduct?.id ? 1 : 10;
      const { adInfo } = product;
      const params = {
        mabayaToken: MABAYA_TOKEN,
        enabled: MABAYA_EVENTS_ENABLED,
        platform: deviceType,
        referrerUrl: window.location.href,
        sku,
        regionCode,
        adInfo,
        mPos,
        categoryName,
        tenant
      };

      const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;
      const {
        sponsoredClickService: clickService,
        sponsoredImpressionService: impressionService
      } = getFuntionAnalyticsSponsored(isSponsoredService);

      if (action === 'click') {
        clickService(params);
        const currentUrl = product?.url;
        setSponsoredDisplayDigitalDataClick(sku, false);
        window.location.href = currentUrl;
      }

      if (action === 'impression' && !hasImpressionBeenSent) {
        const combinedSku = filteredProducts.join(',');
        const combinedAdInfo =
          deviceType === 'mobile'
            ? verticalProduct?.adInfo
            : [horizontalProduct?.adInfo, verticalProduct?.adInfo]
                .filter(Boolean)
                .join(',');
        const combinedMPos = deviceType === 'mobile' ? '10' : '1,10';

        const impressionParams = {
          ...params,
          sku: combinedSku,
          adInfo: combinedAdInfo,
          mPos: combinedMPos
        };
        impressionService(impressionParams);
        setSponsoredDisplayDigitalDataImpression(combinedSku, false);
        setHasImpressionBeenSent(true);
      }
    },
    [
      MABAYA_TOKEN,
      MABAYA_EVENTS_ENABLED,
      deviceType,
      regionCode,
      categoryName,
      tenant,
      horizontalProduct,
      verticalProduct,
      hasImpressionBeenSent,
      store
    ]
  );

  return { handleBannerAction, hasImpressionBeenSent };
};

export default useBannerActionHandler;
