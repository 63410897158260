import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ImageGalleryControlButtonsStyles } from './ImageGalleryControlButtons.style';
import noop from '../../../../utils/noop';

const ImageGalleryControlButtons = ({
  onPreviousHeadLine,
  onNextHeadline,
  onMoveOver,
  variant,
  isConnect
}) => {
  const connectClass = isConnect ? 'connect-button' : '';
  return (
    <Fragment>
      <button
        id="PDP_swipe_photo_left"
        type="button"
        className={`headline-control left ${variant} ${connectClass}`}
        onClick={onPreviousHeadLine}
        onMouseMove={onMoveOver}
      >
        <i className="icon csicon-arrow_left" />
      </button>

      <button
        id="PDP_swipe_photo_right"
        type="button"
        className={`headline-control right ${variant} ${connectClass}`}
        onClick={onNextHeadline}
        onMouseMove={onMoveOver}
      >
        <i className="icon i-gallery csicon-arrow_right" />
      </button>
      <style jsx>{ImageGalleryControlButtonsStyles}</style>
    </Fragment>
  );
};

ImageGalleryControlButtons.defaultProps = {
  onMoveOver: noop,
  variant: 'transparent',
  isConnect: false
};

ImageGalleryControlButtons.propTypes = {
  onPreviousHeadLine: PropTypes.func.isRequired,
  onNextHeadline: PropTypes.func.isRequired,
  onMoveOver: PropTypes.func,
  variant: PropTypes.oneOf(['opaque', 'transparent', 'smallAndTransparent']),
  isConnect: PropTypes.bool
};

export default ImageGalleryControlButtons;
