import css from 'styled-jsx/css';
import { typography } from '../../config/styles/index';

const stickersStyles = css`
  .sticker-group {
    flex-wrap: wrap;
    gap: 8px;
    &.pod-badges-PDP {
      padding-left: 30px;
      &.SOFTLINE {
        padding-left: 0;
        margin-bottom: 3px;
        margin-top: 12px;
      }
      &.POD {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
    &.pod-badges-LIST {
      padding-left: 0;
      padding-right: 0;
    }
    @mixin mobileToTablet {
      &.pod-badges-PDP {
        padding-left: 4px;
        &.SOFTLINE {
          padding-left: 0;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    .pod-badges-item {
      text-transform: none;
      border-radius: 3px;
      letter-spacing: 0;
      padding: 1px 6px 3px 6px;
      margin-right: 4px;
      line-height: 1.7rem;
      &-4_GRID,
      &-LIST {
        font-size: ${typography.base};
      }
      &-PDP {
        font-size: ${typography.base};
      }
      @mixin mobileToTablet {
        font-size: ${typography.sm2};
        line-height: 1.3rem;
        &-LIST {
          line-height: 1.4rem;
          font-size: ${typography.sm};
          margin-bottom: 4px;
          width: auto;
          margin-right: 0;
        }
        &-PDP {
          font-size: ${typography.base};
          line-height: 1.7rem;
          margin-right: 0;
          width: auto;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default stickersStyles;
