import css from 'styled-jsx/css';
import { colors } from '../../../../../config/styles';

const PDPBasicInfoDesktopStyle = css`
  .basic-details-Desktop {
    .reviews {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      &-hidden {
        display: none;
      }
      &-column {
        margin-top: 10px;
        position: relative;
        display: flex;
        a {
          color: ${colors.green.shade10};
          text-decoration: none;
          margin-left: 5px;
          font-weight: bold;
        }
        &-hidden {
          display: none;
        }
      }
    }

    .corner-details {
      margin-top: 3px;
      display: flex;
      justify-content: space-between;
    }

    .variant-seller-ids {
      display: flex;
      align-items: center;
    }
    .vertical-separator {
      width: 0;
      height: 18px;
      border-left: 1px solid ${colors.gray.shade14};
      margin: 0 4px;
    }
  }

  .basic-details-Desktop.out-of-stock {
    border-bottom: 1px solid ${colors.gray.shade3};
    margin-top: 20px;
  }

  .event-sticker-wrapper {
    margin-top: 20px;
    font-weight: 700;
  }
`;

export default PDPBasicInfoDesktopStyle;
