import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProductColorSwatches from '../../../../components/ProductColorSwatches/ProductColorSwatches';
import Size from '../../../../components/Size/Size';
import { withProduct } from '../../../../utils/ProductContext';
import { withLabels } from '../../../../utils/LabelsContext';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { ProductSpecificationsDesktopStyle } from './ProductSpecificationsDesktop.style';
import noop from '../../../../utils/noop';
import Availability from '../../../../components/Availability/Availability';
import CMRCalculatorDesktop from '../../../../components/CMRCalculator/Desktop';
import CMRApertura from '../../../../components/CMRApertura/CMRApertura';
import constants from '../../../../config/constants';
import ProductPrices from '../../../../components/ProductPrices/ProductPrices';
import Button from '../../../../components/ui/Button/Button';
import { getVariant } from '../../../../utils/variant';
import StockQuantity from '../../../../components/StockQuantity/StockQuantity';
import SellerInfo from '../../../../components/SellerInfo/SellerInfo';
import BodyCopy from '../../../../components/ui/BodyCopy/BodyCopy';
import StockTable from '../../../../components/ui/StockTable/StockTable';
import _ from '../../../../utils/LodashImports';
import AdditionalPDPLabel from '../../../../components/AdditionalPDPLabel/AdditionalPDPLabel';
import MultipurposeBadge from '../../../../components/MultipurposeBadge/MultipurposeBadge';
import NearbyStores from '../../../../components/NearbyStores';
import {
  getPriceForOutOfStock,
  isReaconditionedProduct
} from '../../../../utils/product/productUtils';
import InternationalShipping from '../../../../components/InternationalShipping/InternationalShipping';
import ProductQuantitySelectionContainer from '../../Hardline/Desktop/ProductQuantitySelectionContainer';
import { ReaconditionedContainer } from '../../../../components/ReaconditionedContainer/ReaconditionedContainer';
import { useSelectedDeliveryOption } from '../../../../utils/hooks/UseSelectedDeliveryOption';
import { AvailabilityLocation } from '../../../../components/Availability/AvailabilityLocation/AvailabilityLocation';
import DarInformationCard from '../../../../components/DarInformationCard/DarInformationCard';
import Badges from '../../../../components/Badges/Badges';

const ProductSpecificationsDesktop = ({
  product,
  labels,
  appCtx,
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  isAddToCartInProgress,
  handleRemoveFromCart,
  isRemoveFromCartInProgress,
  addToCartButtonVisible,
  showCollectionButtonVisible,
  isVendorLogin,
  storeSkuData,
  currentTab,
  errorAddingToCart,
  requestProductFromStore,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart,
  sizeChartData,
  fomo
}) => {
  const isInternationalShipping = _.get(
    product,
    'internationalShipping.applicable',
    false
  );
  const [comunaName, setComunaName] = useState('');

  const { regionCode, isRebrandingEnabled, store } = appCtx;
  const {
    id: productId,
    currentVariant: currentVariantId,
    variants,
    additionalPDPLabels,
    isOutOfStock,
    colorMetrics,
    cmrBannerActive = true,
    darSectionActive = false
  } = product;
  const { pumUnits } = product.attributes;
  const variant = getVariant(variants, currentVariantId);
  const maxCartQuantity = _.get(
    variant,
    'maxOnCartAmount',
    constants.MAX_CART_PRODUCT
  );
  const sellerName = _.get(variants, '[0].offerings[0].sellerName', '');
  const is1pSeller = constants.FIRST_PARTY_SELLER_REGEX.test(
    sellerName.toLowerCase()
  );
  const multipurposeBadges = _.get(variant, 'multipurposeBadges', []);
  const isStoreDataVaild = storeSkuData && Object.keys(storeSkuData).length > 0;

  const { prices, cmrPoints, discountBadge, cmrSavings } = variant;

  const isMultipurposeBadgeApplicable = _.get(
    appCtx,
    'siteConfig.toggles.isMultipurposeBadgeApplicable',
    false
  );
  const isMeatStickerApplicableInPDP = _.get(
    appCtx,
    'siteConfig.toggles.isMeatStickerApplicableInPDP',
    false
  );
  const { badges = [], meatStickers = [], promotions } = getVariant(
    product.variants,
    product.currentVariant
  );
  const allowed3PCampaignNamesToRender = _.get(
    appCtx,
    'siteConfig.textDictionary.ALLOWED_3P_CAMPAIGN_NAMES_TO_RENDER',
    'ENVIO GRATIS 3P, ENVIO_GRATIS_3P'
  );
  const freeShippingPromotionFor3p = promotions?.find((promotion) =>
    allowed3PCampaignNamesToRender
      .split(',')
      .map((name) => name.trim())
      .includes(promotion.campaignName)
  );
  const hasStickers =
    (isMeatStickerApplicableInPDP && meatStickers.length > 0) ||
    freeShippingPromotionFor3p;
  const nonEventMeatStickers = (meatStickers || []).filter(
    (sticker) => !['event'].includes(sticker.type)
  );
  const isSoCom = appCtx.store === constants.STORES.so_com;
  const showCMRApertura = isSoCom
    ? _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabledForSoCom', false)
    : _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabled', false);

  const showStoreAvailability = _.get(
    appCtx,
    'siteConfig.toggles.isStoreAvailabilityVisibleInPDP',
    false
  );

  const showCMRCalculator = _.get(
    appCtx,
    'siteConfig.toggles.isCMRCalculatorEnabledPDP',
    false
  );

  const isInternationalBuyVisiblePDP = _.get(
    appCtx,
    'siteConfig.toggles.isInternationalBuyVisiblePDP',
    false
  );

  const addToCartLabel = _.get(additionalPDPLabels, 'addToCart.enabled', false)
    ? _.get(additionalPDPLabels, 'addToCart', {})
    : null;
  const isReaconditionedProd = isReaconditionedProduct(product);

  const { availability } = variant;
  const { zoneConfig } = useSelectedDeliveryOption(
    availability?.shippingOptionType,
    appCtx,
    currentVariantId
  );
  useEffect(() => {
    if (zoneConfig) {
      setComunaName(zoneConfig.name);
    }
  }, [zoneConfig]);

  if (isOutOfStock) {
    return (
      <div
        className="product-specifications"
        data-backend-category={product.merchantCategoryId}
        data-availability={
          product.attributes && product.attributes.isPrimeAvailable
        }
        data-published={product.isPublished}
        data-variations={variants.length}
      >
        <div className="product-specifications-column fa--product-specifications-column__desktop">
          {prices && prices.length > 0 && (
            <div className="prices-container">
              <div className="prices">
                <ProductPrices
                  isVendorLogin={isVendorLogin}
                  currentTab={currentTab}
                  storeSkuData={storeSkuData}
                  prices={getPriceForOutOfStock(prices)}
                  productId={productId}
                  regionCode={regionCode}
                  cmrPoints={cmrPoints}
                  pumUnit={pumUnits}
                  isPumAvailable={isPumAvailable}
                  pumQuantity={pumQuantity}
                  primeShipping={primeShipping}
                  deviceType={constants.DEVICE_DESKTOP}
                />
              </div>
            </div>
          )}
          <SellerInfo product={product} />
          {isReaconditionedProd && (
            <ReaconditionedContainer
              appCtx={appCtx}
              productAttributes={product}
            />
          )}
        </div>
        <style jsx>{ProductSpecificationsDesktopStyle}</style>
      </div>
    );
  }

  return (
    <div
      className="product-specifications"
      data-backend-category={product.merchantCategoryId}
      data-availability={
        product.attributes && product.attributes.isPrimeAvailable
      }
      data-published={product.isPublished}
      data-variations={variants.length}
    >
      <div className="product-specifications-column fa--product-specifications-column__desktop">
        <SellerInfo product={product} />

        {prices && prices.length > 0 && (
          <div
            className={`prices-container
          ${
            isMultipurposeBadgeApplicable && multipurposeBadges.length > 0
              ? 'withMultipurposeBadge'
              : ''
          }
          `}
          >
            <div className="prices">
              <ProductPrices
                isVendorLogin={isVendorLogin}
                currentTab={currentTab}
                storeSkuData={storeSkuData}
                prices={prices}
                productId={productId}
                regionCode={regionCode}
                cmrPoints={cmrPoints}
                pumUnit={pumUnits}
                isPumAvailable={isPumAvailable}
                pumQuantity={pumQuantity}
                primeShipping={primeShipping}
                deviceType={constants.DEVICE_DESKTOP}
                discountBadge={discountBadge}
                darSectionActive={darSectionActive}
              />
              <DarInformationCard visible={darSectionActive} labels={labels} />
            </div>
            {showCMRCalculator && (
              <div className="cmrCalculator-container">
                <CMRCalculatorDesktop
                  errorMessage={labels.CMR_INSTALLMENT_ERROR_MESSAGE}
                  layout="softline"
                />
              </div>
            )}
          </div>
        )}
        {isReaconditionedProd && (
          <ReaconditionedContainer
            appCtx={appCtx}
            productAttributes={product}
          />
        )}
        {isInternationalBuyVisiblePDP && isInternationalShipping && (
          <InternationalShipping />
        )}
        {(badges?.length ||
          meatStickers?.length ||
          freeShippingPromotionFor3p?.badge) && (
          <div className="meatstickers-container">
            <Badges
              badgeItems={
                isMeatStickerApplicableInPDP ? nonEventMeatStickers : badges
              }
              layout="PDP"
              isSticker={hasStickers}
              promotionBadge={freeShippingPromotionFor3p?.badge}
            />
          </div>
        )}
        {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 && (
          <div className="multipurpose-badge">
            <MultipurposeBadge
              badgeItems={multipurposeBadges}
              deviceType="desktop"
              inPdp
            />
          </div>
        )}

        <StockQuantity />

        {colorVariants[selectedColorSwatchIndex] ? (
          <div className="colorSwatches-container">
            <Fragment>
              <BodyCopy isHighlighted size="copy3">{`${
                labels.COLOR
              }: `}</BodyCopy>
              <BodyCopy size="copy3">
                {colorVariants[selectedColorSwatchIndex].label}
              </BodyCopy>
            </Fragment>
            <ProductColorSwatches
              colorVariants={colorVariants}
              productId={product.id}
              selectedColorSwatchIndex={selectedColorSwatchIndex}
              colorSwatchHandler={colorSwatchHandler}
              type={constants.PRODUCT_CONSTANTS.SOFTLINE}
              size="medium"
              isRebrandingEnabled={isRebrandingEnabled}
              appCtx={appCtx}
              colorMetrics={colorMetrics}
            />
          </div>
        ) : (
          <div className="specifications-divider" />
        )}
        {sizes.length !== 0 && (
          <div
            className={`size-container ${isRebrandingEnabled &&
              'mkp-softline-size'}`}
          >
            <Size
              title={labels.SELECTION_SIZE}
              sizes={sizes}
              onSizeChange={sizeChangeHandler}
              selectedSize={selectedSize}
              isSoftline
              sizeChartData={sizeChartData}
              fomo={fomo}
            />
          </div>
        )}
        {isVendorLogin && isStoreDataVaild && (
          <StockTable
            stockData={storeSkuData}
            colorVariants={colorVariants}
            selectedColorSwatchIndex={selectedColorSwatchIndex}
            sizes={sizes}
            requestProductFromStore={requestProductFromStore}
            regionCode={regionCode}
          />
        )}

        {addToCartLabel && (
          <AdditionalPDPLabel
            label={addToCartLabel}
            type="addToCart"
            productType="softline"
            desktop
          />
        )}
        <ProductQuantitySelectionContainer
          errorAddingToCart={errorAddingToCart}
          addToCartButtonVisible={addToCartButtonVisible}
          isAddToCartInProgress={isAddToCartInProgress}
          labels={labels}
          maxCartQuantity={maxCartQuantity}
          handleAddToCart={handleAddToCart}
          handleRemoveFromCart={handleRemoveFromCart}
          isRemoveFromCartInProgress={isRemoveFromCartInProgress}
          isVendorLogin={isVendorLogin}
          sizes={sizes}
          selectedSize={selectedSize}
          isRebrandingEnabled={isRebrandingEnabled}
          setShowLoginForm={setShowLoginForm}
          showLoginForm={showLoginForm}
          setErrorAddingToCart={setErrorAddingToCart}
          layout="softline"
        />
        {!!product.showShippingModal && (
          <div className="delivery-options-wrapper">
            {comunaName && (
              <div className="avl-content">
                <AvailabilityLocation comunaName={comunaName} />
              </div>
            )}
            <div className="dlv-opt-wrapper">
              <Availability
                isSoftline
                sizes={sizes}
                colorVariants={colorVariants}
                regionCode={regionCode}
              />
              {(!!store || is1pSeller) && showStoreAvailability && (
                <NearbyStores sizes={sizes} colorVariants={colorVariants} />
              )}
            </div>
          </div>
        )}
        {showCollectionButtonVisible && (
          <div className="addToCart-container fa--add-to-cart__desktop">
            <Button type="outline" onClick={handleAddToCart} size="xtra-large">
              <span>
                {labels.SEE_COLLECTION_BUTTON_LABEL}
                <i className="csicon-arrow_down_small" />
              </span>
            </Button>
          </div>
        )}
        {showCMRApertura && cmrBannerActive && (
          <CMRApertura prices={prices} cmrSavings={cmrSavings} isSoftline />
        )}
      </div>
      <style jsx>{ProductSpecificationsDesktopStyle}</style>
    </div>
  );
};

ProductSpecificationsDesktop.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  requestProductFromStore: noop,
  isAddToCartInProgress: false,
  handleRemoveFromCart: noop,
  isRemoveFromCartInProgress: false,
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  storeSkuData: [],
  currentTab: undefined,
  errorAddingToCart: false,
  showLoginForm: false,
  sizeChartData: {},
  fomo: {}
};

ProductSpecificationsDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  handleAddToCart: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  handleRemoveFromCart: PropTypes.func,
  isRemoveFromCartInProgress: PropTypes.bool,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.array,
  currentTab: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool,
  sizeChartData: PropTypes.object,
  fomo: PropTypes.object
};

export default withProduct(
  withLabels(withApplicationContext(ProductSpecificationsDesktop))
);
