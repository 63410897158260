export default `<svg width="60" height="80" viewBox="0 0 60 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 8C0 3.58172 3.58172 0 8 0H52C56.4183 0 60 3.58172 60 8V59.4912C60 62.5158 58.2942 65.2818 55.5916 66.6397L32.7145 78.1337C30.386 79.3036 27.6335 79.2661 25.3377 78.0331L4.21477 66.6884C1.61928 65.2945 0 62.5867 0 59.6406V8Z" fill="#AAD500"/>
<rect x="5" y="5" width="50" height="56" rx="6" fill="white"/>
<path d="M30.1899 41.1444H32.5838V28.3412C31.9489 28.3412 31.34 28.5934 30.8911 29.0423C30.4422 29.4913 30.1899 30.1002 30.1899 30.7351V41.1444Z" fill="#AAD500"/>
<path d="M34.2129 41.1444H36.6079V28.3412C36.2934 28.341 35.982 28.4029 35.6914 28.5231C35.4009 28.6433 35.1368 28.8197 34.9144 29.042C34.692 29.2643 34.5156 29.5282 34.3952 29.8187C34.2748 30.1092 34.2129 30.4206 34.2129 30.7351V41.1444Z" fill="#AAD500"/>
<path d="M24.422 41.3745C20.8164 41.3745 19.3217 38.8692 19.4714 36.3103C19.5883 34.3217 21.1179 32.0579 24.2788 32.0579C27.8528 32.0579 29.2874 35.2974 28.7673 37.6957H22.051C22.1854 38.596 23.0584 39.2166 24.2362 39.2953C25.6468 39.3893 26.5569 38.3305 26.5569 38.3305L28.375 39.2909C27.4867 40.7692 26.074 41.3745 24.422 41.3745ZM24.2734 34.1393C22.5787 34.1284 22.0597 35.5673 22.0696 35.976H26.3985C26.4389 35.6165 26.0915 34.1502 24.2734 34.1393Z" fill="#AAD500"/>
<path d="M18.214 35.5803C17.8283 33.9654 16.8482 32.8237 15.2639 32.3025C13.8206 31.8141 12.2756 32.0993 11.4005 32.9296V28.3145C11.0854 28.3136 10.7734 28.3749 10.4821 28.4949C10.1908 28.6149 9.92609 28.7912 9.70309 29.0137C9.48008 29.2362 9.30319 29.5005 9.18254 29.7915C9.06189 30.0825 8.99986 30.3944 9 30.7095V41.1231H11.0377L11.1066 40.5058C12.9924 42.0202 16.0331 41.3318 17.2503 39.7792C18.1779 38.6047 18.5833 37.1296 18.214 35.5803ZM15.8102 37.4989C15.5229 38.4375 14.8608 39.0155 13.8763 39.128C12.9454 39.2373 12.0779 38.9346 11.4496 38.1676V35.2973C11.6441 35.0324 11.889 34.8084 12.1702 34.6383C12.4515 34.4683 12.7636 34.3554 13.0885 34.3063C15.3502 34.0703 16.2112 35.966 15.8102 37.4989Z" fill="#AAD500"/>
<path d="M34.2268 24.8076H36.6207V12C36.3055 12 35.9934 12.0622 35.7023 12.1832C35.4113 12.3041 35.1469 12.4813 34.9245 12.7046C34.7021 12.928 34.5259 13.193 34.4062 13.4846C34.2865 13.7762 34.2255 14.0885 34.2268 14.4037V24.8076Z" fill="#AAD500"/>
<path d="M20.8552 14.2792H23.1813V12H20.8421C20.3389 11.9993 19.8406 12.0978 19.3757 12.29C18.9107 12.4822 18.4883 12.7643 18.1325 13.1201C17.7768 13.4758 17.4947 13.8983 17.3025 14.3632C17.1103 14.8282 17.0117 15.3265 17.0125 15.8296V27.3697C17.6476 27.3697 18.2568 27.1174 18.706 26.6683C19.1551 26.2191 19.4075 25.6099 19.4075 24.9747V19.4024H22.1128V17.1232H19.4075V15.7269C19.4075 15.3429 19.56 14.9747 19.8315 14.7032C20.103 14.4317 20.4712 14.2792 20.8552 14.2792Z" fill="#AAD500"/>
<path d="M32.62 16.1855C32.1039 16.1849 31.603 16.3598 31.1996 16.6816C30.0217 15.8315 28.2987 15.389 26.3921 16.0336C24.8122 16.557 23.8376 17.6911 23.4541 19.2973C23.087 20.8269 23.4945 22.3041 24.4112 23.4721C25.6131 25.0094 28.6199 25.6934 30.4959 24.214L30.685 24.8084H32.6145V16.1855H32.62ZM30.2534 21.925C29.5792 22.7029 28.6975 22.881 27.7721 22.775C26.7887 22.6658 26.1332 22.1381 25.8491 21.205C25.4492 19.6753 26.3058 17.7501 28.5533 17.9818C29.7879 18.173 30.2534 19.0744 30.2534 19.0744V21.925Z" fill="#AAD500"/>
<path d="M45.951 16.6816C44.7732 15.8315 43.0502 15.389 41.1436 16.0336C39.5637 16.557 38.589 17.6911 38.2055 19.2973C37.8384 20.8269 38.246 22.3041 39.1627 23.4721C40.3645 25.0094 43.3714 25.6934 45.2474 24.214L45.4364 24.8084H47.366V16.1855C46.8518 16.1861 46.353 16.361 45.951 16.6816ZM45.0038 21.9261C44.3296 22.704 43.4479 22.8821 42.5224 22.7761C41.5391 22.6669 40.8835 22.1391 40.5994 21.2061C40.1996 19.6764 41.0562 17.7512 43.3037 17.9829C44.5383 18.1741 45.0038 19.0755 45.0038 19.0755V21.9261Z" fill="#AAD500"/>
<path d="M47.3575 32.5219C46.8413 32.5203 46.3401 32.6954 45.9371 33.0179C44.7582 32.1679 43.0352 31.7254 41.1296 32.3689C39.5497 32.8923 38.5751 34.0275 38.1916 35.6337C37.8245 37.1688 38.2321 38.6394 39.1488 39.8085C40.3506 41.3447 43.3575 42.0298 45.2346 40.5493L45.4236 41.1437H47.3521V32.5219H47.3575ZM44.992 38.2603C44.3168 39.0393 43.4351 39.2163 42.5096 39.1114C41.5263 39.0022 40.8707 38.4744 40.5866 37.5414C40.1867 36.0117 41.0433 34.0865 43.2908 34.3171C44.5255 34.5094 44.992 35.4097 44.992 35.4097V38.2603Z" fill="#AAD500"/>
<path d="M31.9963 44.6194C29.8734 44.6194 28.2705 46.2026 28.2705 48.3026C28.2705 50.4026 29.8701 52 31.9963 52C34.1225 52 35.7363 50.4113 35.7363 48.3026C35.7363 46.1938 34.1313 44.6194 31.9963 44.6194ZM31.9963 50.2179C30.9496 50.2179 30.1913 49.4127 30.1913 48.3015C30.1913 47.2176 30.9671 46.4003 31.9963 46.4003C33.0255 46.4003 33.8177 47.2176 33.8177 48.3015C33.8177 49.3853 33.0387 50.2179 31.9952 50.2179H31.9963Z" fill="#AAD500"/>
<path d="M44.5652 44.62C43.4638 44.5348 42.5242 45.1062 41.9987 45.434C41.3955 44.9478 40.7258 44.6233 39.867 44.6233C38.2696 44.6233 36.6394 45.5815 36.6394 45.5815V51.8356H38.5023V46.6479C38.8028 46.4294 39.3458 46.401 39.6495 46.401C40.2865 46.401 41.0295 46.9003 41.0295 47.7438V51.8323H42.8935V47.274C42.9022 47.0841 42.8679 46.8947 42.793 46.72C44.0364 46.0262 45.4917 46.555 45.4917 47.7438V51.8323H47.3557V47.7154C47.3579 45.7159 45.8108 44.7151 44.5652 44.62Z" fill="#AAD500"/>
<path d="M23.2689 48.0046C23.4131 47.1491 24.1616 46.4007 25.0466 46.4007C25.6818 46.4073 26.2989 46.6129 26.8112 46.9885L27.7639 45.4927C27.0009 44.9323 26.0807 44.6265 25.134 44.6187C23.3793 44.6187 21.9731 45.6523 21.4978 47.1579C22.1653 47.2374 22.7879 47.5351 23.2689 48.0046Z" fill="#AAD500"/>
<path d="M26.8557 49.5854C26.3392 49.9918 25.7013 50.213 25.0442 50.2137C24.74 50.2121 24.4422 50.1258 24.1843 49.9646C24.1898 50.04 24.1941 50.1165 24.1941 50.194C24.1941 50.7481 24.043 51.2917 23.7571 51.7663C24.1975 51.92 24.6608 51.9976 25.1272 51.9957C26.8754 51.9957 27.8511 50.9851 27.8511 50.9851L26.8557 49.5854Z" fill="#AAD500"/>
<path d="M21.1337 51.9144C22.0805 51.9144 22.848 51.1469 22.848 50.2001C22.848 49.2534 22.0805 48.4858 21.1337 48.4858C20.187 48.4858 19.4194 49.2534 19.4194 50.2001C19.4194 51.1469 20.187 51.9144 21.1337 51.9144Z" fill="#AAD500"/>
</svg>`;