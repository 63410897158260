import css from 'styled-jsx/css';
import { typography, zIndex } from '../../config/styles/index';

const badgesStyles = css`
  .pod-badges {
    display: flex;
    align-items: center;

    @mixin mobileToTablet {
      align-items: flex-end;
    }

    &-item {
      text-transform: uppercase;
      text-align: center;
      padding: 7px 8px;
      font-size: ${typography.sm1};
      line-height: 1.56rem;
      margin-right: 12px;
      letter-spacing: 1px;
      word-break: break-word;

      &:last-child {
        margin-right: 0;
        margin-top: 0;
      }

      &-4_GRID {
        font-size: ${typography.sm2};
        line-height: 1.32rem;
        margin-right: 6px;
      }

      &-LIST {
        margin-bottom: 8px;
        padding: 5px 5px 6px 5px;
        font-size: ${typography.sm};
        line-height: 1.44rem;
        margin-right: 6px;
      }

      @mixin desktop {
        &-4_GRID {
          padding: 5px;
        }

        &-3_GRID {
          padding: 6px 8px;
        }
      }

      @mixin tabletOnly {
        &-4_GRID {
          padding: 3px 5px;
          width: 77px;
        }
        &-3_GRID {
          padding: 6px 5px;
          font-size: ${typography.sm2};
          line-height: 1.32rem;
          margin-right: 6px;
        }
      }

      @mixin mobileToTablet {
        &-2_GRID {
          font-size: ${typography.xs1};
          line-height: 1.08rem;
          letter-spacing: 0.3px;
          padding: 4px;
          margin-right: 6px;
        }

        &-LIST {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 8px;
          display: block;
          width: 100%;
          padding: 5px 4px 5px 4px;
          font-size: ${typography.xs1};
          line-height: 1.08rem;
          letter-spacing: 0.8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-PDP {
      @mixin desktop {
        padding-left: 30px;
      }

      &.SOFTLINE {
        padding-left: 0;
      }

      .pod-badges-item {
        font-size: ${typography.sm};
        line-height: 1.44rem;
        padding: 6px 12px;
        margin-right: 16px;
        @mixin phabletToTablet {
          padding: 6px;
        }
        &:last-child {
          @mixin phabletToTablet {
            margin-right: 0;
          }
        }
      }

      @mixin mobileToTablet {
        position: absolute;
        left: -15px;
        top: 18px;
        z-index: ${zIndex.pod.badges};
        flex-direction: column;
        align-items: flex-start;

        &.SOFTLINE {
          top: 0;
        }

        .pod-badges-item {
          margin-bottom: 12px;
          padding: 7px 12px;
          letter-spacing: 1px;
          margin-right: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-LIST {
      padding-bottom: 6px;
      flex-wrap: wrap;
      @mixin mobileToTablet {
        margin-top: 12.5px;
      }
    }
  }
`;

export default badgesStyles;
