/* eslint-disable no-underscore-dangle */
import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
// import LazyLoad from 'react-lazyload';
import {
  CARTLINE_QUANTITIES_COOKIE_NAME,
  isTottusStandalone,
  parseCartQuantities
} from '@digital-retail/store-manager';
import {
  SponsoredProductsContext,
  SponsoredStrip
} from '@digital-retail/sponsored-provider-library';
import constants from '../../config/constants';
import HardLineProductContainer from '../HardLineProductContainer/HardLineProductContainer';
import SoftLineProductContainer from '../SoftLineProductContainer/SoftLineProductContainer';
import { withProduct } from '../../utils/ProductContext';
import AdditionalInformation from '../../components/AdditionalInformation/AdditionalInformation';
import { ProductContainerStyles } from './ProductContainer.style';
import _ from '../../utils/LodashImports';
// import PromotionProducts from '../../components/PromotionProducts/PromotionProducts';
import { withApplicationContext } from '../../utils/ApplicationContext';
import ProductBundle from '../../components/ProductBundle/ProductBundle';
import { getMatchingVariants, getVariant } from '../../utils/variant';
import TypeAVariant from '../../components/TypeAVariant/TypeAVariant';
import Heading from '../../components/ui/Heading/Heading';
import TypeCProduct from '../../components/TypeCProduct/TypeCProduct';
import { withLabels } from '../../utils/LabelsContext';
import { withCart } from '../../utils/CartContext';
import { BazaarVoiceComponent } from '../../components/BazaarVoice/BazaarVoice';
import { getTenant } from '../../utils/tenantHelper';
import SurveyBar from '../../components/SurveyBar/SurveyBar';
import { updateBVReviews, abortBVInterval } from '../../utils/bazaarVoiceUtils';
import { withRatings } from '../../utils/RatingsContext';
import WithRouter from '../../utils/WithRouter';
import { withSizeInfo } from '../../utils/SizeInfoContext';
import noop from '../../utils/noop';
import NutritionalDetails from '../../components/NutritionalDetails/NutritionalDetails';
import ProductsCarousel from '../../components/ProductsCarousel/ProductsCarousel';
import { withConfig } from '../../utils/ConfigurationContext';
import MyListAlert from '../../components/MyLists/MyListsAlert/MyListAlert';
import { withMyLists } from '../../utils/MyListsContext';
import ComparativeTable from '../../components/ComparativeTable/ComparativeTable';
import Slots from '../../components/Slots/Slots';
import useReco from '../../utils/useReco';
import { withUser } from '../../utils/UserContext';
import { ReviewSection } from '../../external_dependencies/ratingAndReviews/ReviewSection';
import {
  getFuntionAnalyticsSponsored,
  setSponsoredDisplayDigitalDataRenderPDP
} from './utils';
import CookieStorage from '../../utils/CookieStorage';
import SponsoredBannerDisplay from '../SponsoredDisplay/SponsoredDisplay';
import useBannerActionHandler from '../../utils/hooks/useBannerActionHandler';
import { useStripActionHandler } from '../../utils/hooks/useStripActionHandler';
import { fetchToggleFromStorage } from '../../utils/experiments';

const CookiesPopover = dynamic(
  () => import('../../components/CookiesPopover/CookiesPopover'),
  {
    ssr: false,
    loading: () => ''
  }
);

const getAvailableLinkedProducts = (linkedProducts) => {
  return Object.keys(linkedProducts).map((id) => linkedProducts[id]);
};
const productHasExcludedCategories = ({ product, categories, regionCode }) => {
  let productCategory = null;
  const excludedCategories = categories.split(',');
  if (regionCode === 'cl') {
    productCategory = product.defaultParentCategoryId;
  } else if (regionCode === 'pe' || regionCode === 'co') {
    productCategory = product.merchantCategoryId;
  }
  return excludedCategories.includes(productCategory);
};
const handleDDSponsoredProductCarouselLoaded = (
  widgets1,
  widgets2,
  dyCarousels,
  widgetsCarousels
) => {
  const carouselsForDigitalData = [];
  widgets1.map((w) => {
    if (w.type === 'carousel') {
      carouselsForDigitalData.push({
        name: w.name,
        position: carouselsForDigitalData.length + 1
      });
    }
    return null;
  });
  carouselsForDigitalData.push({
    name: constants.SPONSORED_PRODUCTS_CAROUSEL_HEADING,
    position: carouselsForDigitalData.length + 1
  });
  widgets2.map((w) => {
    if (w.type === 'carousel') {
      carouselsForDigitalData.push({
        name: w.name,
        position: carouselsForDigitalData.length + 1
      });
    }
    return null;
  });
  const details = {
    numCarousels: dyCarousels + widgetsCarousels.length + 1,
    carousels: carouselsForDigitalData
  };
  const eventToDigitalData = new CustomEvent('DDPDPCarouselsLoad', {
    bubbles: true,
    detail: details
  });
  window.dispatchEvent(eventToDigitalData);
};

const handleSPCarouselImpressions = (
  sponsoredProductsCarouselsViewedProducts
) => {
  const eventToDigitalData = new CustomEvent('DDPDPBundlesImpressions', {
    bubbles: true,
    detail: { payload: sponsoredProductsCarouselsViewedProducts }
  });
  window.dispatchEvent(eventToDigitalData);
};

const inHouseCarouselsViewedProducts = {};
const sponsoredProductsCarouselsViewedProducts = {};
const comparativeTableViewedProducts = {};
const comparativeTableA2CProducts = new Set();
let comparativeTableClickedProduct = '';

const ProductContainer = ({
  product,
  appCtx,
  labels,
  cart,
  ratings,
  fetchSizeInfo,
  storeQueryParam,
  config,
  myLists
}) => {
  const { regionCode, tenant, isRebrandingEnabled, store, deviceType } = appCtx;
  const {
    MABAYA_TOKEN,
    MABAYA_EVENTS_ENABLED,
    FEATURE_TOGGLE_SPONSORED_VAS_SERVICE
  } = config;
  const [sponsoredProducts, setSponsoredProducts] = useState([]);
  const [rutHash, setRutHash] = useState('');
  const { addedLists, myListMetrics } = myLists || {};
  const [pdpAB, setPdpAb] = useState(undefined);
  const intervalRef = useRef();
  const commentsRef = useRef();

  const layoutType = product.layoutType || constants.DEFAULT_LAYOUT_TYPE;
  const specifications = _.get(product, 'attributes.specifications', []);
  const topSpecifications = _.get(product, 'attributes.topSpecifications', []);
  const gender =
    [...specifications, ...topSpecifications].find(
      (attribute) => attribute.name === constants.GENDER_ATTRIBUTE
    )?.value ?? undefined;
  const { viewTemplate, nutritionSpecifications = null } = product.attributes;
  const [showSpecifications, setShowSpecifications] = useState(
    specifications.length > 0
  );

  const {
    variants,
    id: pId,
    merchantCategoryId,
    currentVariant: currentVariantId,
    defaultParentCategoryId = '',
    brandName,
    brandId,
    linkedProducts = {},
    changeVariant,
    maxOnCartAmount,
    stockUnits
  } = product;
  const currentVariant = getVariant(variants, currentVariantId);
  const okayToShopBarcodes = _.get(currentVariant, 'okayToShopBarcodes', []);

  const isSoftline =
    layoutType === constants.PRODUCT_CONSTANTS.SOFTLINE &&
    viewTemplate !== constants.VIEW_TEMPLATE.TYPE_A;

  const shouldShowSponsoredStrip = fetchToggleFromStorage(
    'SHOW_SPONSORED_STRIP',
    appCtx?.siteConfig
  );
  const { productList: productListResponse } = useContext(
    SponsoredProductsContext
  );
  const { stripAds, sdAds } = (Array.isArray(productListResponse)
    ? productListResponse
    : []
  )
    .filter((sponsor) => sponsor.productId !== pId)
    .reduce(
      (acc, elem) => {
        if (elem?.widget?.includes('strip'))
          return { ...acc, stripAds: [...acc.stripAds, elem] };
        return { ...acc, sdAds: [...acc.sdAds, elem] };
      },
      { stripAds: [], sdAds: [] }
    );

  const isFalabellaProduct =
    product.sellerInfo?.sellerName?.toLowerCase() === 'falabella';
  const stripProduct = isFalabellaProduct
    ? stripAds.filter(
        (adProduct) =>
          !['tottus', 'sodimac'].includes(adProduct.sellerName.toLowerCase())
      )[0]
    : stripAds[0];
  const horizontalProduct = sdAds[0];
  const verticalProduct = sdAds[1];
  const { handleBannerAction } = useBannerActionHandler({
    appCtx,
    config,
    categoryName: product.currentVariant,
    horizontalProduct,
    verticalProduct
  });
  const { handleStripAction } = useStripActionHandler({
    appCtx,
    config,
    categoryName: product.currentVariant,
    horizontalProduct,
    verticalProduct
  });
  const [hasStripBeenSeen, setHasStripBeenSeen] = useState();

  // Since the strip is shown all the top, the impression always will be sent
  useEffect(() => {
    if (hasStripBeenSeen) return;
    const interval = setInterval(() => {
      const isWindowDefined = typeof window !== 'undefined';
      if (!isWindowDefined) return;
      if (!hasStripBeenSeen && stripProduct && shouldShowSponsoredStrip) {
        handleStripAction(stripProduct, 'impression');
        setSponsoredDisplayDigitalDataRenderPDP(stripProduct, true);
        setHasStripBeenSeen(true);
        clearInterval(interval);
      }
    }, 100);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [
    hasStripBeenSeen,
    stripProduct,
    shouldShowSponsoredStrip,
    handleStripAction
  ]);

  const isPDPWidgetGlobalEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isPDPWidgetGlobalEnabled',
    false
  );
  const abTestingCookieName = _.get(
    appCtx,
    'siteConfig.textDictionary.PDP_BI_AB_COOKIE',
    'pdp_ab'
  );
  const isReviewsShownInPDP = _.get(
    appCtx,
    'siteConfig.toggles.isReviewsShownInPDP',
    true
  );
  const isBVQuestionsEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isBVQuestionsEnabled',
    false
  );
  const bvQuestionsCategoriesExcluded = _.get(
    appCtx,
    'siteConfig.textDictionary.BV_QUESTIONS_CATEGORIES_EXCLUDED',
    ''
  );
  const SponsoredDisplayPosition = _.get(
    appCtx,
    'siteConfig.configurations.SponsoredDisplayPosition',
    {}
  );
  const isInHouseReviewSectionEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isInHouseReviewSectionEnabled',
    false
  );
  const isComparativeTableEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isComparativeTableEnabled',
    false
  );
  const isSponsoredDisplayEnabled = _.get(
    appCtx,
    'siteConfig.toggles.featureEnableSponsoredDisplayforPDP',
    false
  );
  const isComboUiEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isComboUiEnabled',
    false
  );

  const isTypeCProductsEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isTypeCProductsEnabled',
    false
  );
  const isSurveyBarShowninPDP = _.get(
    appCtx,
    'siteConfig.toggles.isSurveyBarShowninPDP',
    false
  );
  const isInhouseBundleWidgetActive = _.get(
    appCtx,
    'siteConfig.toggles.isInhouseBundleWidgetActive',
    false
  );
  const shouldSendContextIdToReco = _.get(
    appCtx,
    'siteConfig.toggles.shouldSendContextIdToReco',
    false
  );
  const shouldSendUserIdToReco = _.get(
    appCtx,
    'siteConfig.toggles.shouldSendUserIdToReco',
    false
  );

  const isCartPersistanceEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isCartPersistanceEnabled',
    false
  );
  const { colorMetrics } = product;

  const { setProductId } = colorMetrics;

  useEffect(() => {
    setProductId(product.id);
  }, []);

  const isOneClickCheckoutEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isOneClickCheckoutEnabled',
    false
  );
  const abortTheInterval = () => {
    clearInterval(intervalRef.current);
  };

  const getSkuIds = (ids) => {
    const getContent = (selector) => {
      const element = document.querySelector(selector);
      const tenantCode = `${getTenant(regionCode)}_`;
      return element
        ? element.innerText.trim().replace(tenantCode, '')
        : undefined;
    };

    const skuIds = ids
      .map((id) => ({ skuId: getContent(id) }))
      .filter((id) => id.skuId);
    return skuIds;
  };

  const getBundles = () => {
    if (product.bundles && product.bundles.length !== 0) {
      return;
    }
    const bundleIds = ['#bundle_1', '#bundle_2'];

    const bundles = getSkuIds(bundleIds);

    if (bundles.length > 0) {
      abortTheInterval();
      product.loadBundles({
        products: [...bundles],
        regionCode,
        site: storeQueryParam
      });
    }
  };
  const slotsConfig = _.get(
    appCtx,
    'siteConfig.textDictionary.PDP_SLOTS',
    'PDP-HRT-1,PDP-HRT-2'
  );
  const slotsMap = slotsConfig.split(',').map((s) => s);
  const userId = shouldSendUserIdToReco ? rutHash : '';
  const { zones, politicalId, priceGroupId } = appCtx;

  const getRecommendedProducts = async (
    slotValue,
    widgetsUUID,
    contextId,
    userIdFromCookie
  ) => {
    const channel = store === 'so_com' ? 'standalone_web' : 'web';
    const userIdValue = isTottusStandalone(store)
      ? userIdFromCookie || ''
      : userId;
    return product.loadRecommendedProducts({
      productId: product.id,
      zones,
      regionCode,
      channel,
      site: storeQueryParam,
      widgetsUUID,
      variantId: product.currentVariant,
      userId: userIdValue,
      slots: slotValue,
      categoryId: _.get(product, 'attributes.GSCCategoryId', ''),
      webCategoryId: defaultParentCategoryId,
      brandName: product.brandName,
      sellerId: _.get(product, 'sellerInfo.sellerId', ''),
      contextId,
      politicalId,
      priceGroupId
    });
  };

  const getSponsoredProducts = async () => {
    const disableSponsoredProductsPDP = _.get(
      appCtx,
      'siteConfig.toggles.disableSponsoredProductsPDP',
      false
    );
    const disableSponsoredProductsSisPDP = _.get(
      appCtx,
      'siteConfig.toggles.disableSponsoredProductsSisPDP',
      false
    );
    if (
      (!disableSponsoredProductsPDP && !appCtx.store) ||
      (appCtx.store && !disableSponsoredProductsSisPDP)
    ) {
      const currentPDPUrl = window.location.href || '';
      const sponsoredProductsData = await product.loadSponsoredProducts({
        productId: product.currentVariant,
        zones,
        regionCode,
        refererUrl: currentPDPUrl,
        deviceType: appCtx.deviceType,
        site: appCtx.store,
        politicalId: appCtx.politicalId,
        brandName,
        gender
      });
      const excludedProductIds = [
        horizontalProduct?.productId,
        verticalProduct?.productId
      ].filter(Boolean);
      const filteredSponsoredProducts = sponsoredProductsData?.filter(
        (products) => !excludedProductIds.includes(products?.productId)
      );
      setSponsoredProducts(filteredSponsoredProducts);
    }
  };
  const initialiseCart = () => {
    if (isCartPersistanceEnabled) {
      const cartPersistCookieValue = CookieStorage.getCookie(
        CARTLINE_QUANTITIES_COOKIE_NAME
      );
      const quantitiesMap = cartPersistCookieValue
        ? parseCartQuantities(cartPersistCookieValue)
        : null;
      if (quantitiesMap) {
        cart.initCartFromPersistance({
          quantitiesMap,
          variants: product.variants,
          product
        });
      }
    }
  };

  const scrollToComments = () => {
    const shouldScroll = window.location.href.includes('#comments');
    if (shouldScroll) {
      setTimeout(() => {
        const commentsHeight = commentsRef.current.offsetTop;
        window.scrollTo({ top: commentsHeight, behavior: 'smooth' });
      }, 2000);
    }
  };

  const abortBundleSearch = () => {
    setTimeout(() => {
      abortTheInterval();
    }, 10000);
  };

  const handleViewedProducts = (payloadTitle, skus) => {
    inHouseCarouselsViewedProducts[payloadTitle] = skus;
  };

  const handleViewedSponsporedProducts = (payloadTitle, skus) => {
    sponsoredProductsCarouselsViewedProducts[payloadTitle] = skus;
  };
  const handleViewedComparativeProducts = (payloadTitle, skus) => {
    comparativeTableViewedProducts[payloadTitle] = skus;
  };
  const handleAddedToCartProducts = (sectionIndex, id) => {
    comparativeTableA2CProducts.add(`${sectionIndex}|${id}`);
  };
  const handleClickedProduct = (sectionIndex, id) => {
    comparativeTableClickedProduct = `${sectionIndex}|${id}`;
  };
  const handleRecoCTImpressions = (payloadTitle, skus) => {
    handleViewedComparativeProducts(payloadTitle, skus);
    setTimeout(() => {
      handleSPCarouselImpressions(comparativeTableViewedProducts);
    }, 1000);
  };
  const handleRecoCarouselImpressions = (payloadTitle, skus) => {
    handleViewedProducts(payloadTitle, skus);
    setTimeout(() => {
      handleSPCarouselImpressions(inHouseCarouselsViewedProducts);
    }, 1000);
  };

  const sponsoredClick = (action, sku, adInfo, mPos) => {
    const params = {
      mabayaToken: MABAYA_TOKEN,
      enabled: MABAYA_EVENTS_ENABLED,
      platform: deviceType,
      referrerUrl: window.location.href,
      sku,
      regionCode,
      adInfo,
      mPos,
      isPDP: true,
      categoryId: defaultParentCategoryId,
      tenant,
      store
    };

    const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;

    const {
      sponsoredClickService,
      sponsoredImpressionService,
      sponsoredVisitService
    } = getFuntionAnalyticsSponsored(isSponsoredService);

    if (action === 'click') {
      sponsoredClickService(params);
    }

    if (action === 'impression') {
      sponsoredImpressionService(params);
    }
    if (action === 'visit') {
      sponsoredVisitService(params);
    }
  };
  const handleComparativeTableImpressions = () => {
    const viewedProductsAreEmpty =
      Object.keys(comparativeTableViewedProducts).length === 0;
    if (window && window._satellite && !viewedProductsAreEmpty) {
      const eventToDigitalData = new CustomEvent(
        'DDPDPComparativeTableImpressions',
        {
          bubbles: true,
          detail: {
            viewed: comparativeTableViewedProducts,
            addedToCart: Array.from(comparativeTableA2CProducts).join(','),
            clicked: comparativeTableClickedProduct
          }
        }
      );
      window.dispatchEvent(eventToDigitalData);
    }
  };

  const isMobileView = deviceType === constants.DEVICE_MOBILE;

  const renderSponsoredBanner = () => {
    const shouldDisplaySponsoredBanner =
      isSponsoredDisplayEnabled &&
      (isMobileView ? horizontalProduct : horizontalProduct && verticalProduct);
    if (!shouldDisplaySponsoredBanner) {
      return null;
    }

    return (
      <div className="banner-styles">
        <SponsoredBannerDisplay
          store={store}
          horizontalProduct={horizontalProduct}
          verticalProduct={verticalProduct}
          onBannerClick={handleBannerAction}
          isMobile={isMobileView}
        />
        <style jsx>{ProductContainerStyles}</style>
      </div>
    );
  };

  const handleUnload = () => {
    handleComparativeTableImpressions();
  };

  const handleViewedSponsoredProducts = (payloadTitle, skus) => {
    handleViewedSponsporedProducts(payloadTitle, skus);
    setTimeout(() => {
      handleSPCarouselImpressions(sponsoredProductsCarouselsViewedProducts);
    }, 1000);
  };

  const renderSponsoredProductCarousel = (widgets1 = []) => {
    if (!sponsoredProducts.length) return null;
    return (
      <ProductsCarousel
        key="sponsored-products"
        products={sponsoredProducts}
        heading={constants.SPONSORED_PRODUCTS_CAROUSEL_HEADING}
        subHeading={constants.SPONSORED_PRODUCTS_CAROUSEL_SUB_HEADING}
        parentVariantID={product.currentVariant}
        carouselPosition={`1_${widgets1.length + 1}`}
        handleViewedProducts={handleViewedSponsoredProducts}
        isSponsored
        sponsoredClick={sponsoredClick}
      />
    );
  };

  useEffect(() => {
    updateBVReviews(ratings);
    const checkForBundle = setInterval(getBundles, 500);
    intervalRef.current = checkForBundle;
    abortBundleSearch();
    scrollToComments();
    getSponsoredProducts();
    initialiseCart();
    return () => {
      abortTheInterval();
      abortBVInterval();
    };
  }, []);
  const { recoData: slot1RecoData, loading: loadingSlot1 } = useReco({
    rutHash,
    abTestingCookieName,
    getRecommendedProducts,
    pdpAB,
    slots: _.get(slotsMap, '[0]', ''),
    index: 0,
    sponsoredProducts,
    politicalId,
    priceGroupId,
    toggles: {
      isInhouseBundleWidgetActive,
      isComparativeTableEnabled,
      isPDPWidgetGlobalEnabled,
      shouldSendContextIdToReco,
      shouldSendUserIdToReco
    },
    store
  });
  const { recoData: slot2RecoData, loading: loadingSlot2 } = useReco({
    isPDPWidgetGlobalEnabled,
    rutHash,
    abTestingCookieName,
    getRecommendedProducts,
    pdpAB,
    slots: _.get(slotsMap, '[1]', ''),
    index: 1,
    sponsoredProducts,
    politicalId,
    priceGroupId,
    toggles: {
      isInhouseBundleWidgetActive,
      isComparativeTableEnabled,
      isPDPWidgetGlobalEnabled,
      shouldSendContextIdToReco,
      shouldSendUserIdToReco
    },
    store
  });

  useEffect(() => {
    const eventFunction = (e) => {
      if (e?.detail?.rutHash) {
        setRutHash(e.detail.rutHash);
      }
      cart.checkBuyNowStatus({
        DDMRutHash: e.detail.rutHash,
        DDMAddresses: e.detail.addresses,
        DDMPayments: e.detail.payments,
        isOneClickCheckoutEnabled,
        product
      });
    };
    const dyFunction = (e) => {
      if (e.detail) {
        setPdpAb(e.detail);
      }
    };
    window.addEventListener('beforeunload', handleUnload);
    window.addEventListener('RUTHASHPOPULATED', eventFunction);
    window.addEventListener('DYNAMICYIELDLOADED', dyFunction);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      window.removeEventListener('RUTHASHPOPULATED', eventFunction);
      window.removeEventListener('DYNAMICYIELDLOADED', dyFunction);
    };
  }, []);

  useEffect(() => {
    myListMetrics.setProduct(product);
  }, []);

  const selectedVariantId = WithRouter('variantId');

  useEffect(() => {
    if (selectedVariantId !== currentVariantId) {
      changeVariant(tenant, currentVariantId, store, regionCode, true);
    }

    const sizes = {};

    variants
      .filter(
        ({ isPurchaseable, attributes }) => isPurchaseable && attributes.size
      )
      .forEach(({ attributes }) => {
        sizes[attributes.size] = true;
      });
    const sizeEquivalencieToggle = _.get(
      appCtx,
      'siteConfig.toggles.isSizeEquivalencyTableVisible',
      false
    );
    const isNewSizeGroupService = _.get(
      appCtx,
      'siteConfig.toggles.isNewSizeGroupService',
      false
    );
    if (sizeEquivalencieToggle) {
      fetchSizeInfo({
        isNewSizeGroupService,
        regionCode,
        productId: pId,
        sizes: Object.keys(sizes),
        backendCategoryId: merchantCategoryId,
        webCategoryId: defaultParentCategoryId,
        brandId
      });
    }
  }, []);

  const { bundles } = product;

  const { colorCode } = _.get(currentVariant, 'attributes', {});

  const availableLinkedProducts = getAvailableLinkedProducts(linkedProducts);
  const typeARecStartIndex = availableLinkedProducts.length > 0 ? 13 : 4;

  useEffect(() => {
    const loadComboData = async () => {
      const toggles = _.get(appCtx, 'siteConfig.toggles');
      const bundleLinks = _.get(currentVariant, 'bundleLinks', null);
      const bundleIds = bundleLinks?.map((bundleLink) => bundleLink.id);
      if (bundleIds) {
        await product.fetchComboData({
          bundleIds,
          bundleLinks,
          zones: zones.split(','),
          politicalAreaId: politicalId,
          priceGroupId,
          regionCode,
          toggles,
          site: storeQueryParam
        });
      }
    };
    const isComboProduct =
      product.productType === 'BUNDLE' &&
      currentVariant.bundleLinks?.length > 0;

    if (!isComboUiEnabled || !isComboProduct || currentVariant.comboData) {
      setShowSpecifications(
        currentVariant.comboData?.hasSpecifications || specifications.length > 0
      );
      return;
    }

    loadComboData().then(() => {
      setShowSpecifications(
        currentVariant.comboData?.hasSpecifications || specifications.length > 0
      );
    });
  }, [currentVariant]);

  const handleAddToCart = ({ skuId, quantity, offeringId }) => {
    appCtx.setBusy(true);
    cart
      .addProduct({
        appCtx,
        products: [{ skuId, productId: product.id, quantity, offeringId }],
        product
      })
      .then(() => {
        appCtx.setBusy(false);
      });
  };

  const defaultPosition = constants.SposonsoredDisplayPosition;

  const catID = product.defaultParentCategoryId;
  const positionForDevice =
    SponsoredDisplayPosition?.positions?.[catID]?.[
      (isMobileView ? 'mobile' : 'desktop')
    ] ?? defaultPosition[isMobileView ? 'mobile' : 'desktop'];

  const renderWidget = (widget, index, slot) => {
    if (!widget.data.length) return <React.Fragment />;
    switch (widget.type) {
      case 'bundles':
        if (isInhouseBundleWidgetActive) {
          return (
            <ProductBundle
              key={`widget-${widget.name}`}
              heading={widget.name}
              variant="recommendation"
              bundles={widget.data}
              selectedProduct={product}
              carouselPosition={`${slot}_${index + 1}`}
              parentVariantID={product.currentVariant}
              handleViewedProducts={handleRecoCarouselImpressions}
              sponsoredClick={sponsoredClick}
            />
          );
        }
        return <React.Fragment />;
      case 'comparative':
        if (isComparativeTableEnabled && widget.data.length >= 3) {
          return (
            <ComparativeTable
              key="comparative-table"
              products={widget.data.slice(0, 6)}
              heading={widget.name}
              carouselPosition={`${slot}_${index + 1}`}
              parentVariantID={product.currentVariant}
              handleViewedProducts={handleRecoCTImpressions}
              handleAddedToCartProducts={handleAddedToCartProducts}
              handleClickedProduct={handleClickedProduct}
              addToCart={handleAddToCart}
              attributes={widget.attributes}
            />
          );
        }
        return <React.Fragment />;
      case 'carousel':
        return (
          <ProductsCarousel
            key={`widget-${widget.name}`}
            products={widget.data}
            heading={widget.name}
            carouselPosition={`${slot}_${index + 1}`}
            parentVariantID={product.currentVariant}
            handleViewedProducts={handleRecoCarouselImpressions}
            sponsoredClick={sponsoredClick}
          />
        );
      default:
        return <React.Fragment />;
    }
  };
  const renderProductCarousel = (widgetConfig = [], slot) => {
    if (!widgetConfig || !(Array.isArray(widgetConfig) && widgetConfig.length))
      return [<React.Fragment key="empty-fragment" />];

    return widgetConfig.map((widget) => {
      const widgetKey = `widget-${widget.name}`;
      if (positionForDevice === 2 && widgetConfig.indexOf(widget) === 1) {
        return (
          <React.Fragment key={`sponsored-banner-${widgetKey}`}>
            {renderSponsoredBanner()}
            {renderWidget(widget, slot)}
          </React.Fragment>
        );
      }
      return renderWidget(widget, slot);
    });
  };

  return (
    <>
      {shouldShowSponsoredStrip && Boolean(stripProduct) && (
        <div
          id="sponsored-strip-container"
          style={{
            width: '100%',
            background: 'rgb(238, 238, 238)',
            paddingTop: isMobileView ? 9 : 12,
            paddingBottom: isMobileView ? 8 : 4
          }}
        >
          <SponsoredStrip
            id="sponsored-strip"
            product={stripProduct}
            containerStyles={{
              maxWidth: 1280,
              width: '100%',
              margin: '0 auto',
              borderRadius: isMobileView ? 0 : '8px',
              cursor: 'pointer'
            }}
            leftPriceStyles={isMobileView ? { paddingTop: '3.5px' } : {}}
            isMobile={isMobileView}
            onStripClick={handleStripAction}
          />
        </div>
      )}
      <section id="product-b2c-ui" className="pdp-body">
        <div className="container">
          <CookiesPopover />
          <div className="container-my-lists-alert">
            <MyListAlert deviceType={deviceType} addedLists={addedLists} />
          </div>
          <div className="productContainer">
            {isSoftline ? (
              <SoftLineProductContainer />
            ) : (
              <HardLineProductContainer />
            )}
          </div>
          <div id="carrusel-recs-1" />
          <div id="carrusel-recs-3" />
          {viewTemplate === constants.VIEW_TEMPLATE.TYPE_A && (
            <React.Fragment>
              <div className="typeA-variants" id="typeA-variants">
                {getMatchingVariants(variants, { colorCode }).map((variant) => {
                  const {
                    id,
                    name: variantName,
                    prices,
                    isPurchaseable,
                    isHDAvailable,
                    isCCAvailable,
                    networkStock
                  } = variant;
                  const availabilityStatus = isPurchaseable;
                  const offeringId = _.get(
                    variant,
                    'offerings[0]offeringId',
                    ''
                  );
                  return (
                    <Fragment>
                      {availabilityStatus && (
                        <div className="typeA-variants-item">
                          <TypeAVariant
                            key={id}
                            variantName={variantName}
                            brandName={brandName}
                            prices={prices}
                            isHomeDeliveryAvailable={isHDAvailable}
                            isPickFromStoreAvailable={isCCAvailable}
                            networkStock={networkStock}
                            variantId={id}
                            offeringId={offeringId}
                            addToCart={handleAddToCart}
                            appCtx={appCtx}
                            maxOnCartAmount={maxOnCartAmount}
                            stockUnits={stockUnits}
                            isRebrandingEnabled={isRebrandingEnabled}
                          />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div id={`carrusel-recs-${typeARecStartIndex}`} />
              <div id={`carrusel-recs-${typeARecStartIndex + 1}`} />
              <div id={`carrusel-recs-${typeARecStartIndex + 2}`} />
            </React.Fragment>
          )}
          {isTypeCProductsEnabled && availableLinkedProducts.length > 0 && (
            <div className="typeC-products-container fb--type-c-products">
              <div className="typeC-heading">
                <Heading variation="title10" level={2}>
                  {isSoftline
                    ? labels.TOGETHER_PRODUCTS
                    : labels.MORE_PRODUCTS_FROM_COLLECTION}
                </Heading>
              </div>
              <div className="typeC-products">
                {availableLinkedProducts.map(({ id: productId }) => {
                  return (
                    <TypeCProduct
                      productId={productId}
                      key={productId}
                      isSoftline={isSoftline}
                      isRebrandingEnabled={isRebrandingEnabled}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {availableLinkedProducts.length > 0 && (
            <React.Fragment>
              <div id="carrusel-recs-4" />
              <div id="carrusel-recs-5" />
              <div id="carrusel-recs-6" />
            </React.Fragment>
          )}
          {bundles &&
            bundles.length > 0 &&
            !product.isOutOfStock &&
            !isInhouseBundleWidgetActive && (
              <div className="additionInformation-container fa--bundled-products">
                <ProductBundle bundles={bundles} selectedProduct={product} />
              </div>
            )}
          {nutritionSpecifications &&
            Object.keys(nutritionSpecifications).length > 0 && (
              <NutritionalDetails
                nutritionalSpefications={nutritionSpecifications}
              />
            )}
          {(showSpecifications || product.longDescription) && (
            <div className="additionInformation-container fa--product-additional-info">
              <AdditionalInformation
                specifications={specifications}
                topSpecifications={!isSoftline ? topSpecifications : []}
                description={
                  !nutritionSpecifications ? product.longDescription : null
                }
                okayToShopBarcodes={okayToShopBarcodes}
                isRebrandingEnabled={isRebrandingEnabled}
                appCtx={appCtx}
              />
            </div>
          )}
          {positionForDevice === 1 && renderSponsoredBanner()}
          <div id="testId-pdp-slot1">
            <Slots
              position={_.get(slotsMap, '[0]', null)}
              isLoading={loadingSlot1}
            >
              {renderProductCarousel(slot1RecoData, 1)}
            </Slots>
            {positionForDevice === 3 && renderSponsoredBanner()}
            <div id="carrusel-recs-2" />
            {renderSponsoredProductCarousel(slot1RecoData)}
          </div>
          {positionForDevice === 4 && renderSponsoredBanner()}
          <div id="carrusel-recs-7" />
          <div id="carrusel-recs-8" />
          <div id="carrusel-recs-9" />
          {isReviewsShownInPDP &&
            (isInHouseReviewSectionEnabled ? (
              <div className="additionInformation-container fa--product-additional-info">
                <ReviewSection />
              </div>
            ) : (
              <>
                {isBVQuestionsEnabled &&
                  !productHasExcludedCategories({
                    product,
                    categories: bvQuestionsCategoriesExcluded,
                    regionCode
                  }) && (
                    <div className="additionInformation-container fa--bazaarvoice-questions">
                      <BazaarVoiceComponent
                        componentName="questions"
                        productId={product.id}
                      />
                    </div>
                  )}
                <div
                  className="additionInformation-container fa--bazaarvoice"
                  ref={commentsRef}
                >
                  <BazaarVoiceComponent
                    componentName="reviews"
                    productId={product.id}
                  />
                </div>
              </>
            ))}
          {positionForDevice === 5 && renderSponsoredBanner()}
          <div id="testId-pdp-slot2">
            {_.get(slotsMap, '[1]', null) && (
              <Slots
                position={_.get(slotsMap, '[1]', null)}
                isLoading={loadingSlot2}
              >
                {renderProductCarousel(slot2RecoData, 2)}
              </Slots>
            )}
          </div>

          <div id="carrusel-recs-10" />
          <div id="carrusel-recs-11" />
          <div id="carrusel-recs-12" />
        </div>
        <div className="additionInformation-container">
          {isSurveyBarShowninPDP && <SurveyBar />}
        </div>
        <style jsx>{ProductContainerStyles}</style>
      </section>
    </>
  );
};

ProductContainer.defaultProps = {
  fetchSizeInfo: noop
};

ProductContainer.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  fetchSizeInfo: PropTypes.func,
  storeQueryParam: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  myLists: PropTypes.object.isRequired
};

export { productHasExcludedCategories, handleDDSponsoredProductCarouselLoaded };

export default withUser(
  withSizeInfo(
    withRatings(
      withCart(
        withLabels(
          withApplicationContext(
            withConfig(withProduct(withMyLists(ProductContainer)))
          )
        )
      )
    )
  )
);
