import css from 'styled-jsx/css';
import { colors, icons, typography, zIndex } from '../../../../config/styles';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';

const ProductSpecificationsMobileStyle = css`
  .product-specifications {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    &-column {
      width: 100%;
      span {
        font-weight: bold; 
      }
      .color-swatch-container {
        position: relative;
        @mixin mobileToTablet {
          margin: 15px 0;
        }
        .expand-swatch {
          position: absolute;
          right: 0;
          top: 13px;
          font-size: 10px;
        }
      }
    }
  }
  .flex {
    display: flex
  }
  .stock-table {
    @mixin mobileToPhablet {
      margin-left: 25px;
    }
  }
  .flex > * {
    flex: 1;
  }
  .prices {
    margin-bottom: 20px;
    &.withMultipurposeBadge {
      margin-bottom: 16px;
    }
  }

  .cmr-points {
    font-size: 11px;
    margin-top: 11px;
    color: ${colors.black.shade7};
  }

   .badges {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    position:relative;
    margin-bottom: 12px;
  }

  .boomIcon{
    background-image: url("${svgToDataUri(icons.boomIcon)}");
    background-repeat: no-repeat;
    width: 60px;
    height: 13px;
    margin: 10px 0;
    display: block;
  }

  .cmrCalculator-container {
    border-bottom: 1px solid ${colors.gray.shade14};
  }

  :global(.csicon-arrow_right) {
    margin-left: 10px;
  } 
  
  .addToCart-container {
    box-shadow: 0 -3px 26px rgba(0,0,0,.1);
    background-color: ${colors.white.shade1};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 21px;
    z-index: ${zIndex.pdp.addToCartButton};

    :global(.csicon-arrow_down_small) {
      margin-left: 9px;
      font-size: ${typography.xs2};
    }
  }

  .additional-info {
    margin: 20px 0;
    background-color: ${colors.gray.shade32};
    padding: 18px 24px 27px;

    .info-label {
      font-weight: 400;
      line-height: 23px;
      margin-top: 16px;
    }

    :global(.specifications-action) {
      margin-top: 20px;
    }
  }
  .multipurpose-badge {
    width: 100%;
    margin-bottom: 19.5px;
    @mixin mobileToTablet {
      margin-bottom: 24px;
    }
  }
  .variant-seller-ids {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
`;
export { ProductSpecificationsMobileStyle };
