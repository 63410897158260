import React from 'react';
import PropTypes from 'prop-types';
import { SellerRatingsStyles } from './SellerRatings.style';
import {
  getSellerClass,
  getSellerLink,
  validateSellerName
} from '../SellerInfo/helpers';
import constants from '../../config/constants';
import _ from '../../utils/LodashImports';
import { withApplicationContext } from '../../utils/ApplicationContext';
import defaultConfig from '../../config/url/default.config';
import { withConfig } from '../../utils/ConfigurationContext';

const SellerRatings = ({ sellerInfo, appCtx, config }) => {
  const {
    sellerName = '',
    rating: { score, ontime, cancellations, customer }
  } = sellerInfo;

  const { regionCode, store } = appCtx;
  const ENVIRONMENT = _.get(config, 'ENVIRONMENT', 'production');
  const isSISExperienceOn = _.get(
    appCtx,
    'siteConfig.toggles.isSISExperienceOn',
    false
  );
  const {
    SELLER_RATING_SOLD_BY = 'Vendido por',
    SELLER_RATING_RECOMMENDED = '¡Recomendado por falabella.com!',
    SELLER_RATING_RECOMMENDED_SOCOM = '¡Recomendado por sodimac!',
    SELLER_RATING_SEE_SELLER_PRODUCTS = 'Ver todos los productos de este vendedor',
    SELLER_RATING_ONTIME_HIGH = 'Realiza entregas a tiempo',
    SELLER_RATING_ONTIME_MEDIUM = 'Pocas entregas con demora',
    SELLER_RATING_ONTIME_LOW = 'Realiza entregas con demora',
    SELLER_RATING_CANCELLATIONS_HIGH = 'Cumple con sus entregas',
    SELLER_RATING_CANCELLATIONS_MEDIUM = 'Pocos pedidos cancelados',
    SELLER_RATING_CANCELLATIONS_LOW = 'Suele cancelar pedidos',
    SELLER_RATING_CUSTOMER_HIGH = 'Ofrece un buen servicio',
    SELLER_RATING_CUSTOMER_MEDIUM = 'Buen servicio con pocos reclamos',
    SELLER_RATING_CUSTOMER_LOW = 'Suele recibir reclamos',
    SELLER_RATING_RANGE_LIMITS = '3,4',
    SELLER_INSUFFICIENT_SCORE_LABEL = 'No existe suficiente información para calificar a este vendedor.'
  } = _.get(appCtx, 'siteConfig.textDictionary', {});

  const [lowRatingLimit, mediumRatingLimit] = SELLER_RATING_RANGE_LIMITS.split(
    ','
  ).map((x) => Number(x));
  const filledStars = Math.floor(score);
  const emptyStars = 5 - filledStars;

  const sellerNameLower = sellerName.toLowerCase();
  const sellerClass = getSellerClass({ constants, sellerNameLower });
  const sellerRedirectionMap = _.get(
    appCtx,
    'siteConfig.configurations.sellerRedirectionMap',
    defaultConfig.SELLER_REDIRECTION_MAP[ENVIRONMENT][regionCode]
  );
  const validatedSellerName = validateSellerName(sellerNameLower, regionCode);

  const getRatingText = (ratingValue) => {
    if (ratingValue < lowRatingLimit) return 'low';
    if (ratingValue < mediumRatingLimit) return 'medium';
    return 'high';
  };
  const ratingDictionary = {
    ontime: {
      high: SELLER_RATING_ONTIME_HIGH,
      medium: SELLER_RATING_ONTIME_MEDIUM,
      low: SELLER_RATING_ONTIME_LOW
    },
    cancellations: {
      high: SELLER_RATING_CANCELLATIONS_HIGH,
      medium: SELLER_RATING_CANCELLATIONS_MEDIUM,
      low: SELLER_RATING_CANCELLATIONS_LOW
    },
    customer: {
      high: SELLER_RATING_CUSTOMER_HIGH,
      medium: SELLER_RATING_CUSTOMER_MEDIUM,
      low: SELLER_RATING_CUSTOMER_LOW
    }
  };

  return (
    <div className="seller-ratings">
      <div className="top-section">
        <div className="sold-by-wrapper">
          <span className="sold-by-text">{SELLER_RATING_SOLD_BY}</span>
          <a
            href={getSellerLink({
              sellerClass,
              regionCode,
              sellerName:
                sellerClass === 'homecenter'
                  ? validatedSellerName.toUpperCase()
                  : sellerName,
              env: ENVIRONMENT,
              isSISExperienceOn,
              store,
              sellerRedirectionMap
            })}
            id="seller-ratings-seller-name"
            data-test-id="seller-ratings-seller-name"
            className="seller-name bold underline"
          >
            {validatedSellerName}
          </a>
        </div>

        {score === 5 && (
          <div
            data-test-id="seller-ratings-recommended"
            className="recommended"
          >
            {store === constants.STORES.so_com
              ? SELLER_RATING_RECOMMENDED_SOCOM
              : SELLER_RATING_RECOMMENDED}
          </div>
        )}
        {score > 0 && score <= 5 && (
          <div className="score">
            {filledStars > 0 &&
              [...Array(filledStars)].map(() => (
                <span className="rating-star filled" />
              ))}
            {emptyStars > 0 &&
              [...Array(emptyStars)].map(() => (
                <span className="rating-star empty" />
              ))}
          </div>
        )}
      </div>
      <div
        data-test-id="seller-ratings-ratings"
        className="middle-section ratings"
      >
        {score ? (
          <>
            <div className="rating ontime">
              <span className={`icon ${getRatingText(ontime)}`} />
              <span className="text">
                {ratingDictionary.ontime[getRatingText(ontime)]}
              </span>
            </div>
            <div className="rating cancellations">
              <span className={`icon ${getRatingText(cancellations)}`} />
              <span className="text">
                {ratingDictionary.cancellations[getRatingText(cancellations)]}
              </span>
            </div>
            <div className="rating customer">
              <span className={`icon ${getRatingText(customer)}`} />
              <span className="text">
                {ratingDictionary.customer[getRatingText(customer)]}
              </span>
            </div>
          </>
        ) : (
          <div data-test-id="insufficient_score_label">
            {SELLER_INSUFFICIENT_SCORE_LABEL}
          </div>
        )}
      </div>
      <a
        id="seller-ratings-see-seller-products"
        data-test-id="seller-ratings-see-seller-products"
        href={getSellerLink({
          sellerClass,
          regionCode,
          sellerName:
            sellerClass === 'homecenter'
              ? validatedSellerName.toUpperCase()
              : sellerName,
          env: ENVIRONMENT,
          isSISExperienceOn,
          sellerRedirectionMap,
          store
        })}
        className="bottom seller-link underline"
      >
        {SELLER_RATING_SEE_SELLER_PRODUCTS}
      </a>
      <style jsx>{SellerRatingsStyles}</style>
    </div>
  );
};

SellerRatings.propTypes = {
  sellerInfo: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export { SellerRatings };

export default withApplicationContext(withConfig(SellerRatings));
