import Router from 'next/router';
import { isStandalone } from '@digital-retail/store-manager';
import noop from './noop';
import { isWindow } from './deviceChecker';
import constants from '../config/constants';
import { getStoreValue } from './tenantHelper';

// url    : /search?site=falabella-cl&Ntt=termo&page=0
// asUrl  : /falabella-cl/search?Ntt=termo&page=0

// url   : /search
// asUrl : /falabella-cl/search

const serializeQuery = (params = {}, ignoreQuery = []) => {
  return Object.keys(params)
    .filter((name) => ignoreQuery.indexOf(name) === -1)
    .map((name) => {
      const value = encodeURIComponent(params[name]);
      return `${encodeURIComponent(name)}=${value}`;
    })
    .join('&');
};

const parseQueryString = (url) => {
  const params = {};
  if (url.indexOf('?') === -1) {
    return params;
  }
  url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .filter((param) => param)
    .filter((p) => !!p)
    .forEach((param) => {
      const [name, value] = param.split('=');
      params[name] = value || '';
    });

  return params;
};

const filterQueryString = (url = '', allowedParams = []) => {
  const params = parseQueryString(url);
  const filteredParams = {};

  Object.entries(params).forEach(([key, value]) => {
    if (allowedParams.includes(key)) {
      filteredParams[key] = value;
    }
  });

  return filteredParams;
};

const navigate = ({
  url,
  asUrl,
  params,
  site,
  clearParams,
  extraQuery = '',
  onRouteChange = noop
}) => {
  const oldParams = parseQueryString(Router.router.asPath);

  const newParams = clearParams
    ? params
    : {
        ...oldParams,
        ...params
      };
  const paramString = serializeQuery(newParams || {});
  const href = `${url}?site=${site}${paramString &&
    `&${paramString}`}${extraQuery}`;
  const asHref = `/${site}${asUrl || url}?${paramString}`;
  onRouteChange();
  Router.push(href, asHref);
};

const navigateWithUrl = ({
  rootUrl = '/search',
  site,
  url,
  onRouteChange = noop,
  store = null
}) => {
  const href = `${rootUrl}?site=${site}&${url}${
    store && !url.match(/store/g) ? `&store=${store}` : ''
  }`;
  let asHref = `/${site}${rootUrl}${url && `?${url}`}`;
  if (store && store !== 'tienda') {
    asHref = asHref.replace('falabella', getStoreValue(store));
  }
  onRouteChange();
  Router.push(href, asHref);
};

const reload = ({ onRouteChange = noop, ignoreQuery = [] }) => {
  const paramString = serializeQuery(Router.router.query, ignoreQuery);

  const href = `${Router.router.pathname}?${paramString}`;

  let asHref = Router.router.asPath;
  asHref = asHref
    .split('?')
    .map((item) => {
      return item
        .split('&')
        .filter((param) => ignoreQuery.indexOf(param.split('=')[0]) === -1)
        .join('&');
    })
    .filter((item) => item)
    .join('?');

  onRouteChange();
  if (Router.router) Router.push(href, asHref);
};

const redirect = (url, doReplace = false) => {
  if (doReplace) {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
};

const reloadWithParams = ({
  onRouteChange = noop,
  ignoreQuery = [],
  newParams = '',
  tenant = 'falabella-cl'
}) => {
  const paramString = serializeQuery(Router.router.query, ignoreQuery);
  const href = `${Router.router.pathname}?${paramString}${newParams}`;

  let asHref = Router.router.asPath;
  if (Router.router.pathname === '/noResult') {
    const { query } = Router.router;
    const termo = query.Ntt;
    if (termo) {
      redirect(`/${tenant}/search?Ntt=${termo}`);
    }
    return;
  }

  asHref = asHref
    .split('?')
    .map((item) => {
      return item
        .split('&')
        .filter((param) => ignoreQuery.indexOf(param.split('=')[0]) === -1)
        .join('&');
    })
    .filter((item) => item)
    .join('?');

  onRouteChange();
  if (Router.router) Router.push(href, asHref);
  // else if (Router.router) Router.replace(href,asHref);
};

const changeProductRoute = ({ id, slug, variant, site, store, regionCode }) => {
  const queryString = window.location.search || '';
  let url = `/product?site=${site}&productId=${id}&productName=${slug}&variantId=${variant}`;
  let tenant = site;
  const standAlone = isStandalone({ store });
  const productKeyWord = standAlone ? constants.ARTICULO : constants.PRODUCT;
  const isSIS = store && !standAlone;

  if (isSIS) {
    url += `&store=${store}&subdomain=${store}`;

    if (regionCode) {
      tenant = `${
        store === constants.STORES.linio || store === constants.STORES.tienda
          ? 'falabella'
          : store
      }-${regionCode}`;
    }
  }
  const asUrl = `/${tenant}/${productKeyWord}/${id}/${slug}/${variant}${queryString}`;
  Router.replace(url, asUrl, { shallow: true });
};

const navigateForCategory = ({
  url,
  params,
  site,
  clearParams,
  onRouteChange = noop,
  categoryId,
  categoryName
}) => {
  const { id, name, ...oldParams } = parseQueryString(Router.router.asPath);

  const newParams = clearParams
    ? params
    : {
        ...oldParams,
        ...params
      };
  const paramString = serializeQuery(newParams || {});
  const href = `${url}?site=${site}&id=${categoryId}&name=${categoryName}&${paramString}`;
  const asHref = `/${site}${url}/${categoryId}/${categoryName}?${paramString}`;

  onRouteChange();
  Router.push(href, asHref);
};

const redirectToUrl = (url, res) => {
  if (isWindow()) {
    redirect(url, true);
    return;
  }

  if (res) {
    res.writeHead(constants.REDIRECT_CODE, {
      Location: url,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Expires: '0',
      Pragma: 'no-cache'
    });
    res.end();
  }
};

const isReloadRequiredOnZoneChange = () => {
  const currentPathName = Router.router.pathname;
  if (currentPathName === '/noResult' && !Router.router.query.Ntt) {
    return false;
  }
  if (currentPathName === '/notFound') {
    return false;
  }
  return true;
};

export {
  navigate,
  navigateWithUrl,
  redirect,
  reload,
  changeProductRoute,
  navigateForCategory,
  redirectToUrl,
  serializeQuery,
  parseQueryString,
  filterQueryString,
  reloadWithParams,
  isReloadRequiredOnZoneChange
};
