import css from 'styled-jsx/css';
import { colors } from '../../../../../config/styles';

const ProductSpecificationStyle = css`
  .basic-details-Desktop {
    &.out-of-stock {
      margin-top: 20px;
      @mixin tabletToDesktop {
        width: 600px;
        border-bottom: 1px solid ${colors.gray.shade3};
      }
    }
    .corner-details {
      display: flex;
      justify-content: space-between;
      margin-top: 3px;
    }
    .variant-seller-ids {
      display: flex;
      align-items: center;
    }
    .vertical-separator {
      width: 0;
      height: 18px;
      border-left: 1px solid ${colors.gray.shade14};
      margin: 0 4px;
    }
  }
  .event-sticker-wrapper {
    font-weight: 700;
  }
  .reviews {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding-bottom: 18px;
    &.hidden {
      display: none;
    }

    &-column {
      margin-top: 3px;
      position: relative;
      width: 54%;
      display: flex;
      &.hidden {
        visibility: hidden;
      }
    }
  }
`;

export default ProductSpecificationStyle;
