/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { RRProvider } from '@digital-retail/rating-and-reviews';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import { SponsoredProvider } from '@digital-retail/sponsored-provider-library';
import ProductContext from '../utils/ProductContext';
import constants from '../config/constants';
import apiConfig from '../config/api/apiConfig';
import httpService from '../utils/httpService';
import { isWindow } from '../utils/deviceChecker';
import {
  navigate,
  redirect,
  filterQueryString,
  serializeQuery
} from '../utils/RouteHandler';
import ProductContainer from '../containers/ProductContainer/ProductContainer';
import CartContext from '../utils/CartContext';
import Cart from '../components/Cart/Cart';
import { BazaarVoiceHead } from '../components/BazaarVoice/BazaarVoice';
import PDPSeo from '../utils/SEO/PDPSeo';
import { getNetworkStock } from '../utils/AvailabilityHelper';
import RatingsContext from '../utils/RatingsContext';
import SizeInfoContext from '../utils/SizeInfoContext';
import _ from '../utils/LodashImports';
import { withApplicationContext } from '../utils/ApplicationContext';
import { getOpenGraphData } from '../utils/SeoUtils';
import { withDigitalData } from '../utils/DigitalDataContext';
import loadGoogleMapScript from '../external_dependencies/googleMaps';
import { checkSpecification } from '../utils/product/productUtils';
import { getRequestUrl, getProductUrlParams } from '../utils/urlHelper';
import { withUser } from '../utils/UserContext';
import defaultConfig from '../config/url/default.config';
import { isEmpty } from '../utils/jsUtils';

import {
  getFuntionAnalyticsSponsored,
  getUrlBase
} from '../containers/ProductContainer/utils';
import { withConfig } from '../utils/ConfigurationContext';
import OkToShopInfoHead from '../components/AdditionalInformation/ProductInfo/OkToShopHead';
import { sponsoredClickServiceByXLP } from '../services/sponsoredService';

const nextConfig = getConfig();
let { env } = (nextConfig && nextConfig.publicRuntimeConfig) || {
  env: 'dev'
};
const { GOOGLE_MAP_API_KEY = '' } =
  (nextConfig && nextConfig.publicRuntimeConfig) || {};
if (env === 'uat') {
  env = 'beta';
}

const Product = ({
  productData,
  siteId,
  openGraphData,
  appCtx,
  digitalData,
  storeQueryParam,
  firstName,
  isUserLoggedIn,
  theme,
  config
}) => {
  const { FEATURE_TOGGLE_SPONSORED_VAS_SERVICE } = config;
  const hasRun = useRef(false);
  const { sponsoredClickService } = getFuntionAnalyticsSponsored(
    FEATURE_TOGGLE_SPONSORED_VAS_SERVICE
  );

  useEffect(() => {
    if (window && !hasRun.current) {
      hasRun.current = true;
      const currentUrl = new URL(window.location);
      const params = currentUrl.searchParams;

      const sponsoredClickDataEncoded = params.get('sponsoredClickData');

      if (sponsoredClickDataEncoded) {
        const sponsoredClickData = decodeURIComponent(
          sponsoredClickDataEncoded
        );
        const clickEventSponsoredProduct = JSON.parse(sponsoredClickData);
        params.delete('sponsoredClickData');
        window.history.replaceState(null, '', currentUrl.toString());

        const isXLPclickEvent = clickEventSponsoredProduct.isXLP;

        if (isXLPclickEvent) {
          sponsoredClickServiceByXLP(clickEventSponsoredProduct);
        } else {
          sponsoredClickService(clickEventSponsoredProduct);
        }
      }
    }
  }, []);

  digitalData.setPage(constants.PAGES.PDP);
  const environment = _.get(
    nextConfig,
    'publicRuntimeConfig.ENVIRONMENT',
    'production'
  );
  const isSecondaryRatingsFormEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isSecondaryRatingsFormEnabled',
    true
  );
  const isReviewValidationWithOrder = _.get(
    appCtx,
    'siteConfig.toggles.isReviewValidationWithOrder',
    true
  );
  const isOkToShopFeatureEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isOkToShopFeatureEnabled',
    false
  );
  const isSodimacSA = isSodimacStandalone(appCtx.store);
  const rrLabel = isSodimacSA
    ? _.get(
        appCtx,
        'siteConfig.textDictionary.SO_COM_BV_WRITE_REVIEW',
        'Evaluar'
      )
    : _.get(appCtx, 'siteConfig.textDictionary.BV_WRITE_REVIEW', 'Calificar');
  const recommendedThreshold = _.get(
    appCtx,
    'siteConfig.textDictionary.BV_RECOMMENDED_THRESHOLD',
    80
  );
  useEffect(() => {
    loadGoogleMapScript(GOOGLE_MAP_API_KEY, siteId);
  }, []);

  useEffect(() => {
    if (appCtx && appCtx.busy) {
      appCtx.setBusy(false);
    }
    appCtx.attachResizeListener();
  }, [productData]);
  const gender =
    [
      ...(productData?.attributes?.specifications || []),
      ...(productData?.attributes?.topSpecifications || [])
    ].find((attribute) => attribute.name === constants.GENDER_ATTRIBUTE)
      ?.value ?? undefined;

  const configValue = {
    regionCode: appCtx.regionCode,
    deviceType: appCtx.deviceType,
    zones: appCtx.zones,
    site: appCtx.store || appCtx.subdomain,
    store: appCtx.store || appCtx.subdomain,
    urlBase: getUrlBase(config),
    providedId: productData?.currentVariant,
    providedParam: 'productId',
    multiWidget: true,
    ...(gender ? { gender } : {})
  };
  return (
    <ProductContext.Provider data={productData}>
      <SponsoredProvider config={configValue}>
        <CartContext.Provider>
          <RatingsContext.Provider>
            <SizeInfoContext.Provider>
              <RRProvider
                product={productData}
                firstName={firstName}
                label={rrLabel}
                isLoggedIn={isUserLoggedIn}
                isSecondaryRatingsFormEnabled={isSecondaryRatingsFormEnabled}
                isReviewValidationWithOrder={isReviewValidationWithOrder}
                tenant={appCtx.regionCode}
                environment={environment}
                theme={theme}
                recommendedThreshold={recommendedThreshold}
                isSodimacStandAlone={isSodimacSA}
              >
                <PDPSeo
                  siteProps={{
                    regionCode: siteId,
                    product: {
                      brandName: productData.brandName,
                      name: productData.name,
                      slug: productData.slug,
                      variantId: productData.currentVariant,
                      productId: productData.id,
                      isOutOfStock: productData.isOutOfStock,
                      description: productData.description,
                      sellerName: _.get(
                        productData,
                        'variants[0].offerings[0].sellerName',
                        ''
                      )
                    },
                    openGraphData,
                    variants: productData.variants,
                    breadcrumb: productData.breadCrumb,
                    appCtx,
                    store: storeQueryParam
                  }}
                />
                {isOkToShopFeatureEnabled && <OkToShopInfoHead />}
                <BazaarVoiceHead
                  country={siteId}
                  isRebrandingEnabled={appCtx.isRebrandingEnabled}
                />
                <Cart />
                <ProductContainer storeQueryParam={storeQueryParam} />
              </RRProvider>
            </SizeInfoContext.Provider>
          </RatingsContext.Provider>
        </CartContext.Provider>
      </SponsoredProvider>
    </ProductContext.Provider>
  );
};

const shouldRedirect = (responseType) => {
  return responseType === constants.SEARCH_REDIRECT_STATUS;
};

const redirectNoProductResult = (response, site) => {
  if (isWindow()) {
    navigate({
      url: '/notFound',
      site
    });
    return;
  }

  if (response) {
    const url = `/${site}/notFound`;
    response.writeHead(constants.REDIRECT_CODE, { Location: url });
    response.end();
  }
};

const redirectProduct = (response, url) => {
  if (isWindow()) {
    redirect(url, true);
    return;
  }

  if (response) {
    response.writeHead(constants.HTTP_RES_CODE.PERM_REDIRECT, {
      Location: url
    });
    response.end();
  }
};

const isCanonicalUrl = (currentUrl, productData) => {
  const { slug } = productData;
  const urlPath = currentUrl.match('(/{0,1}[^?#]*)')[0].split('/');
  if (!urlPath[4]) return true;
  const currentName = decodeURIComponent(urlPath[4]);
  return currentName === slug;
};

const shouldSEORedirect = (appCtx, currentUrl, productData) => {
  // Need to remove this once we get confirmation from SEO team
  const isRedirect301EnabledForPDP = _.get(
    appCtx,
    'siteConfig.toggles.isRedirect301EnabledForPDP'
  );
  return isRedirect301EnabledForPDP && !isCanonicalUrl(currentUrl, productData);
};

const getRedirectToFcomUrl = ({ product, envPath, region, params }) => {
  const paramString = serializeQuery(params);
  const regionGeoDomain = region === 'cl' ? '' : `.${region}`;
  return `https://${envPath}.falabella.com${regionGeoDomain}/falabella-${region}/product/${
    product.id
  }/${product.slug}/${product.currentVariant}${
    paramString ? `?${paramString}` : ''
  }`;
};

const getRedirectToSubdomain = ({
  product,
  envPath,
  region,
  subDomain,
  params = ''
}) => {
  const paramString = serializeQuery(params);
  const topLevelDomain = region === 'cl' ? 'com' : `com.${region}`;
  return `https://${envPath}${subDomain}.falabella.${topLevelDomain}/${subDomain}-${region}/product/${
    product.id
  }/${product.slug}/${product.currentVariant}${
    paramString ? `?${paramString}` : ''
  }`;
};

const getRedirectToNotFoundUrl = ({ storeKey, envPath, region, subDomain }) => {
  const topLevelDomain = region === 'cl' ? region : `com.${region}`;
  const topLevelDomainForMKP = region === 'cl' ? 'com' : `com.${region}`;
  if (storeKey === 'marketplace') {
    return `https://${envPath}.falabella.${topLevelDomainForMKP}/falabella-${region}/notFound`;
  }
  return `https://${envPath}.${subDomain}.${topLevelDomain}/${subDomain}-${region}/notFound`;
};

function isValidProductSite({ CONFIGS_PER_STORE, storeKey, productSites }) {
  const { validProductSites } = CONFIGS_PER_STORE[storeKey];
  return productSites.some((productSite) =>
    validProductSites.includes(productSite)
  );
}

const checkMatchSellerRegex = ({ sellerName, sellerRedirections }) => {
  return sellerRedirections.find((redirection) =>
    redirection.regex.test(sellerName.toUpperCase())
  );
};

const getRedirectUrl = ({ ctx, product, CONFIGS_PER_STORE }) => {
  const { productSites = [] } = product;
  const { store, regionCode, subdomain, url } = ctx;
  const filteredParams = filterQueryString(
    url,
    constants.ALLOWED_PARAMS_REDIRECT
  );

  const storeKey = store ? store.toLowerCase() : 'marketplace';
  const thereIsMatch = isValidProductSite({
    CONFIGS_PER_STORE,
    storeKey,
    productSites
  });
  const envPath = env;
  const sisEnvPath = env === 'www' ? '' : `${env}-`;

  if (!thereIsMatch) {
    if (CONFIGS_PER_STORE[storeKey].redirectsToFallback) {
      return getRedirectToFcomUrl({
        product,
        envPath,
        region: regionCode,
        params: filteredParams
      });
    }
    return getRedirectToNotFoundUrl({
      storeKey,
      envPath,
      region: regionCode,
      subDomain: subdomain
    });
  }
  // handle if a redirection to SIS is needed
  if (CONFIGS_PER_STORE[storeKey]?.sellerRedirections?.length) {
    const sellerMatch = checkMatchSellerRegex({
      sellerName: product.sellerInfo?.sellerName,
      sellerRedirections: CONFIGS_PER_STORE[storeKey]?.sellerRedirections
    });
    if (
      sellerMatch &&
      !isEmpty(sellerMatch) &&
      process.env.NODE_ENV !== 'development' &&
      subdomain !== sellerMatch.forceSubdomain
    ) {
      return getRedirectToSubdomain({
        product,
        envPath: sisEnvPath,
        region: regionCode,
        subDomain: sellerMatch.forceSubdomain,
        params: filteredParams
      });
    }
  }

  return '';
};

Product.getInitialProps = async (
  { config },
  { query, req, res, asPath },
  appCtx
) => {
  const { productName, site, subdomain, store: storeQuery, ...params } = query;
  const store = storeQuery || subdomain;
  const siteId = `${query.site.substr(query.site.indexOf('-') + 1, 2)}`;

  const updatedParams = getProductUrlParams({ params, appCtx, storeQuery });

  const getProductDetailsUrl = apiConfig.getProductDetailsUrl({
    base: isWindow() ? config.API_HOST : config.CLUSTER_API_HOST,
    siteId,
    params: updatedParams
  });
  const response = await httpService(appCtx).get(getProductDetailsUrl);
  const { data, error } = response;

  if (error) {
    throw new Error(constants.ERROR_MESSAGE_TEXT);
  }
  const productData = data.data;
  productData.stockUnits = {};
  const isPaintCanWithoutColor = checkSpecification({
    product: productData,
    specId: 'Color',
    specValue: 'Sin Color'
  });

  if (isPaintCanWithoutColor) {
    redirectNoProductResult(res, query.site);
  }

  if ('variants' in productData) {
    productData.variants.forEach((v) => {
      productData.stockUnits[v.id] = getNetworkStock(v.networkStock);
    });
  }

  if (productData.linkedProducts) {
    Object.values(productData.linkedProducts).forEach((linkedProducts) => {
      linkedProducts.variants.forEach((v) => {
        productData.stockUnits[v.id] = getNetworkStock(v.networkStock);
      });
    });
  }

  const isNotFound = data.responseType === constants.NOT_FOUND;
  if (isNotFound) {
    redirectNoProductResult(res, query.site);
    return undefined;
  }
  // return the product JSON with out-of-stock state (for conditional display in UI)
  productData.isOutOfStock = data.responseType === constants.OUT_OF_STOCK;

  if (shouldRedirect(data.responseType)) {
    if (!isWindow() && res && !(res.finished || res.headersSent)) {
      res.setHeader('Cache-Control', 'no-cache');
    }
    redirectProduct(res, data.data.altUrl);
    return undefined;
  }
  const openGraphData = getOpenGraphData(
    productData,
    appCtx,
    store,
    'PDP',
    config
  );
  const currentUrl = isWindow()
    ? asPath
    : getRequestUrl(req.url, appCtx.tenant);

  if (shouldSEORedirect(appCtx, currentUrl, productData)) {
    if (!isWindow() && res && !(res.finished || res.headersSent)) {
      res.setHeader('Cache-Control', 'no-cache');
    }
    const urlToRedirect = encodeURI(openGraphData.canonicalUrl);

    redirectProduct(res, urlToRedirect);
    return undefined;
  }

  const urlToRedirect = getRedirectUrl({
    ctx: appCtx,
    product: productData,
    CONFIGS_PER_STORE: defaultConfig.CONFIGS_PER_STORE
  });
  if (urlToRedirect) {
    redirectProduct(res, urlToRedirect);
    return undefined;
  }

  const disableQuantitySelection = _.get(
    appCtx,
    'siteConfig.toggles.disableQuantitySelection',
    false
  );

  productData.disableQuantitySelection = disableQuantitySelection;

  return { productData, siteId, openGraphData, storeQueryParam: storeQuery };
};

Product.propTypes = {
  productData: PropTypes.object.isRequired,
  openGraphData: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  digitalData: PropTypes.object.isRequired,
  storeQueryParam: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export {
  redirectNoProductResult,
  shouldSEORedirect,
  isCanonicalUrl,
  shouldRedirect,
  redirectProduct,
  getRedirectUrl,
  isValidProductSite,
  getRedirectToNotFoundUrl,
  getRedirectToFcomUrl
};
export default withUser(
  withApplicationContext(withDigitalData(withTheme(withConfig(Product))))
);
