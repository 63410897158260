export default `<svg width="60" height="80" viewBox="0 0 60 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 8C0 3.58172 3.58172 0 8 0H52C56.4183 0 60 3.58172 60 8V59.4912C60 62.5158 58.2942 65.2818 55.5916 66.6397L32.7145 78.1337C30.386 79.3036 27.6335 79.2661 25.3377 78.0331L4.21477 66.6884C1.61928 65.2945 0 62.5867 0 59.6406V8Z" fill="#1F140F"/>
<path d="M49 5H11C7.68629 5 5 7.68629 5 11V55C5 58.3137 7.68629 61 11 61H49C52.3137 61 55 58.3137 55 55V11C55 7.68629 52.3137 5 49 5Z" fill="white"/>
<path d="M50.9787 37.7098C50.9787 37.8677 50.834 37.9961 50.6563 37.9961H9.34659C9.16886 37.9961 9.02539 37.8677 9.02539 37.7098V29.2834C9.02539 29.1244 9.17006 28.9961 9.34659 28.9961H50.6563C50.834 28.9961 50.9787 29.1244 50.9787 29.2834V37.7098Z" fill="#FFEF00"/>
<path d="M51.1651 39H8.83586C8.37586 39 8 38.6359 8 38.1902V28.8097C8 28.3629 8.3748 28 8.83586 28H51.1651C51.6263 28 51.9998 28.3629 51.9998 28.8097V38.1902C51.9998 38.6359 51.6275 39 51.1651 39ZM9.01093 38.02H50.9911V28.9799H9.01093V38.02Z" fill="#1F140F"/>
<path d="M21.5386 31.0664L23.3774 36.1113H21.7057L21.4898 35.404H19.776L19.5612 36.1113H17.8906L19.7713 31.0664H21.5399H21.5386ZM20.111 34.2814H21.1687L20.6469 32.5016L20.111 34.2814Z" fill="#1F140F"/>
<path d="M27.8074 31.0664V32.1731H25.475V32.9226H27.784V34.0155H25.4681V35.0173H28.0164V36.1091H23.9141V31.0664H27.8074Z" fill="#1F140F"/>
<path d="M32.1961 32.6074C32.1341 32.5219 32.0991 32.4785 32.0571 32.4442C31.7721 32.1716 31.3497 31.9992 30.9423 32.0278C30.8103 32.0369 30.6807 32.0678 30.5395 32.1419C30.46 32.183 30.3397 32.2754 30.3456 32.4214C30.3479 32.5162 30.4004 32.6006 30.4695 32.6508C30.5955 32.7295 30.8884 32.7865 31.0204 32.8082C31.1944 32.8367 31.7559 32.9234 32.2416 33.0877C32.5475 33.1881 33.3075 33.4665 33.3075 34.4738C33.3075 35.2235 32.8603 35.6102 32.6444 35.7745C32.0116 36.2524 31.1979 36.2617 30.9888 36.2617C30.2721 36.2617 29.5822 36.1065 28.9974 35.657C28.7755 35.4859 28.6495 35.3683 28.4824 35.147L29.6756 34.3929C29.818 34.5719 29.8555 34.5925 29.9184 34.6564C30.1869 34.9313 30.6072 35.0524 31.0204 35.0466C31.4583 35.0397 31.7991 34.896 31.7897 34.6462C31.7873 34.5765 31.7757 34.5583 31.7617 34.5241C31.7303 34.451 31.6637 34.3883 31.5179 34.338C31.3437 34.2742 30.9305 34.208 30.4916 34.1156C29.8158 33.9798 29.4538 33.8088 29.2167 33.5943C29.0638 33.4573 28.7848 33.1219 28.793 32.5709C28.793 32.4363 28.7907 32.1202 28.9728 31.8076C29.5016 30.8971 30.7765 30.8789 30.9632 30.8789C31.2131 30.8789 32.0933 30.9029 32.802 31.422C32.956 31.535 33.0519 31.6182 33.1765 31.7723L32.1948 32.6085L32.1961 32.6074Z" fill="#1F140F"/>
<path d="M37.9142 31.0664V32.3099H36.6242V36.1091H35.0714V32.3099H33.7734V31.0664H37.9142Z" fill="#1F140F"/>
<path d="M41.2199 31.0675C41.3589 31.0675 41.506 31.0744 41.6448 31.0892C41.8608 31.1108 42.4433 31.1667 42.7993 31.7749C42.8823 31.9243 43.0283 32.2391 43.0283 32.6819C43.0283 33.1598 42.8333 33.5786 42.4504 33.8673C42.3429 33.9482 42.308 33.9985 42.0709 34.0909L43.2675 36.1113H41.5257L40.5719 34.3315H40.1189V36.1113H38.5605V31.0664L41.2199 31.0675ZM40.1189 33.2956H40.7659C41.0517 33.2888 41.3788 33.2603 41.4839 32.9305C41.4977 32.9021 41.5188 32.8084 41.5188 32.7103C41.5188 32.5883 41.4839 32.4514 41.4207 32.3521C41.3717 32.2803 41.3028 32.2391 41.2748 32.2244C41.1137 32.1319 40.9328 32.1239 40.7517 32.1239H40.1179V33.2944L40.1189 33.2956Z" fill="#1F140F"/>
<path d="M48.0661 31.5715C48.3578 31.8635 48.8259 32.4853 48.8259 33.544C48.8259 33.851 48.777 34.8298 48.0741 35.544C47.3841 36.2605 46.4794 36.2742 46.1653 36.2742C45.8514 36.2742 44.9397 36.2605 44.2637 35.5531C43.7826 35.0591 43.5469 34.3233 43.5469 33.5874C43.5469 33.252 43.6017 32.3017 44.2766 31.6079C44.5834 31.3011 45.1697 30.8789 46.1911 30.8789C46.5121 30.8789 47.3818 30.888 48.0647 31.5738M45.4614 32.4522C45.2863 32.6393 45.1054 32.9965 45.1054 33.5885C45.1054 33.6821 45.0983 34.2685 45.3774 34.6324C45.4685 34.7465 45.5722 34.8322 45.7042 34.896C45.8022 34.9394 45.9541 34.9963 46.1911 34.9963C46.4853 34.9963 46.7129 34.9109 46.909 34.6815C47.0958 34.475 47.2569 34.1465 47.2569 33.5396C47.2569 33.4038 47.279 32.6393 46.7351 32.3027C46.5121 32.1669 46.2821 32.1533 46.1911 32.1533C46.073 32.1533 45.7183 32.175 45.4603 32.4534" fill="#1F140F"/>
<path d="M15.1158 31.0664L14.3138 34.2562L13.5293 31.0664H11.3008V36.1102H12.7764V32.6601C12.7764 32.6454 12.7764 32.6407 12.7764 32.6407C12.8452 32.918 13.7372 36.1102 13.7372 36.1102H14.8929C14.8929 36.1102 15.8105 32.8815 15.8618 32.6476V36.1102H17.3456V31.0664H15.1158Z" fill="#1F140F"/>
</svg>`;